export let data = [
  '{"name": "Kiran Gulrajani", "place": "Baner-Pashan Link Road, Pune", "waNumber": "919823969473", "email": "kirangul@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9dcfba5fdad6d2504d2c5d223ff3403765ff4525687c109a4d9457801de1d10d/Screenshot_20220501_043350_WhatsApp.jpg", "scribbleSpace": "I love mornings. I enjoy friendships. I like to write at www.CoEvolveWithKiran.com and would love to do it more. What brings me alive is to help people discover the beauty within, their heartsong. \\n\\nHaving struggled a lot with Learning, Relationships, Money, Time, Adhd, Sexuality etc.. I now like help others to navigate these challenges. And discover their Heartistry and then also possibly their Heartistree. \\ud83d\\ude42\\n\\nI love to sing and dance. And be in nature. Deep Conversations.. and more..", "contribution": "1. How to discover your heartsong/ lifework?\\n2. How to have healthier relationships with yourself & others? What is love?\\n3. Being healthier & happier..\\n4. The artistry of learning together. \\n5. Connecting with the ultimate truth, freedom & God.", "intro": "Heartistry Coach"}',
  '{"name": "Apekshit Yagnik", "place": "Ravet Pune", "waNumber": "918889634724", "email": "yagnikap93@gmail.com", "photoLine": "https://api.typeform.com/responses/files/be5841813f8cce5784d2bfbd553eac0a0f2f838256ec1bb3839697dabfd24af1/IMG_20180724_WA0019.jpg", "scribbleSpace": "Interest - Singing\\nIssues - Laziness/ no control over sleep\\nExpectation - waking up early to adopt good habits like yoga, meditation etc.", "contribution": "Can share real life experiences about problem solving related to personal lives", "intro": "Purchase professional"}',
  '{"name": "Anant Pramod Honap", "place": "Wakad Pune", "waNumber": "919923473884", "email": "anantphonap@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8f0372ac751676814642ea71581139f57581ac08614e5d114aa4b41f754aa16e/IMG_20210120_073627.png", "scribbleSpace": "I am artistic in nature. I am hobbyist & change hobbies time to time. I plan too many things but I lack in ordering, consistency & keeping same pace.", "contribution": "I am people\'s person, I lead the team in complex org structure. I am having exposure to stakeholders management & diversified communication", "intro": "Life Explorer, Violinist"}',
  '{"name": "Swanand Patil", "place": "SB Road, Pune.", "waNumber": "919527995997", "email": "patilswanand23@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0c83baa8845239434c828e46811ac1a8483ad0a1cdd03d8f78dbe54aaecb2842/IMG_20220425_235250.jpg", "scribbleSpace": "Self growth, public speaking,traveling and enjoying life to it\'s fullest :)", "contribution": "Life skills", "intro": "Aspiring 5amer"}',
  '{"name": "Khushboo Kalyani", "place": "C scheme, Jaipur", "waNumber": "917259571121", "email": "khushbookalyani1@gmail.com", "photoLine": "https://api.typeform.com/responses/files/02aa0f495ef7beaa0ab1fa91f035aabf32e73eeaee947922382efc31392b5dfe/kk.jpg", "scribbleSpace": "Hobbies:- Yoga, writing and breathwork. Expectations:- To be an early riser.", "contribution": "Would love to share the right intake of daily nutrition, appropriate food consumption and diet plans. Can share different workouts for various age groups, can help break negative pattern of toxin relationship or habits by suggesting self care routine and working on self healing.", "intro": "Spiritual practitioner"}',
  '{"name": "Lokesh", "place": "Delhi", "waNumber": "919873781997", "email": "lokeshkalra937@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c49df7a4081edc47e075ae88d270c8a5461fa8af8a27b4233533c9b4eb0ef707/6B90B8C9_54E6_4880_B9B9_7C07E9D2893D.jpeg", "scribbleSpace": "Reading, writting", "contribution": "Deep thinker , always open tk learn more", "intro": "Optimistic , vision"}',
  '{"name": "Sandeep Karande", "place": "Mumbai", "waNumber": "919619903224", "email": "karandesandeep@gmail.com", "photoLine": "https://api.typeform.com/responses/files/cd9cbc5a9f6a6ca8a1fa4945e2ab1401e1267b27c99970be0219a7b3743c25b1/IMG_20220618_002952.jpg", "scribbleSpace": "Treks, Hikes and adventure sports", "contribution": "Treks", "intro": "Nature Explorer"}',
  '{"name": "Vivek Singrodia", "place": "Bhandup, Mumbai", "waNumber": "919594756234", "email": "viveksingrodia@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3f3b857291e86353b5eb30a3ec86427dd4ff4ca190d27ec55c7971602ba4758e/BG359758_Vivek_Singrodia.jpg", "scribbleSpace": "Likes meeting new people. Keen interest in finance and technology.", "contribution": "Work together on some new idea", "intro": "Silent Thinker"}',
  '{"name": "Adv Seema Sharma", "place": "Pume", "waNumber": "919823594576", "email": "", "photoLine": "https://api.typeform.com/responses/files/9d5ced75c39e668f7c9a071fd6a7b1215a3e1010b65e60a30109ba162fbfbee7/IMG_20220128_114033.jpg", "scribbleSpace": "I believe I am Lord Shiva\' child..So much divinely connected with Him. Acting Dancing and Writing are my hobbies and my pulling energy to overcome obstacles.I am learning not to expect anything from anyone to keep myself motivated and unhurt. I wish to open Rehabilitation centre for helpless Widows and Divorcee women.I am totally against Gender bias and also fight for Men\'s rights while protecting Supressed \\nwomen. I have bucket list prepared after watching Dasvidhania movie and I am glad that my focus of life to complete my bucket list and leave this beautiful world with uttermost satisfaction and big smile on the face. Recent bucket list item finished is learnt swimming even though 2 major knee surgeries have been performed on my knee after my accident and it was very tough to learn swimming at this age.Still I did that..Hurrey !!? I can float now !!! My major issue in the life is I am very sensitive and sentimental and sometimes act as an Emotional fool \\u263a\\ufe0f", "contribution": "I am avid reader and writer ( 2 Marathi Novels got published) I am also a very good stage performer .I can conduct some fun activitiy and share inputs from a good book .", "intro": "Writer N Advocate"}',
  '{"name": "Sheetal", "place": "Pashan Pune", "waNumber": "919421695368", "email": "majorsheetal@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e42bcb62eb6a9b9f480624a884137ed9ce14e29a7a6f8c339f3dacae5725080b/IMG_20220820_155504__01.jpg", "scribbleSpace": "Interested in knowing myself more.. still exploring what I am good at.. love to travel,walk, talk to people, explore and sustain eco-friendly lifestyle etc etc", "contribution": "No idea.. after 21 days hope I will get some clarity", "intro": "Life explorer"}',
  '{"name": "Kirti", "place": "Pune", "waNumber": "919975059796", "email": "bhutadakirti@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1df01052b550e1d46e670b574a94c8b9593ed09608411addf6e93cfed4eb921c/IMG_20220330_141004.jpg", "scribbleSpace": "Teaching", "contribution": "Nothing", "intro": "Yoga practitioner"}',
  '{"name": "Bhagyashree vishwakarma", "place": "Kalyan", "waNumber": "919321115441", "email": "bhagyashreev481@gmail.com", "photoLine": "https://api.typeform.com/responses/files/440c15450819e289979744d8acd62b8f6df04fd0c8f8fa60665898f86e252af8/IMG_20220811_WA0021.jpg", "scribbleSpace": "Interest- online teaching\\nPlan-economists\\nIssue- PWD catagory with VI", "contribution": "Gift yourself and find hapiness in your own.", "intro": "Graduated in june 2022, Preparing for bank exam"}',
  '{"name": "Manu Mohit Ojha", "place": "Shastri Nagar, Jodhpur, RAJ", "waNumber": "917665924482", "email": "imanuojha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9dfc97879d53dba5719014b4fb92bd3ef4aabdf76c4ce17e1feda86550f16af6/IMG_20220704_WA0007.jpg", "scribbleSpace": "Programmer, designer, guitarist, planning to be a CA", "contribution": "Multifaceted learning. Music + Java + Accounts + Tax everything all at once. Correlation between subjects.", "intro": "CA Student"}',
  '{"name": "Mahesh Kale", "place": "Pune", "waNumber": "918668345182", "email": "tech.mkale@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0bca0364e6ae4e60159d3af4efa44fdcbf09bba3fa8c83ca74b31352de03725a/Screenshot_2022_08_31_215409.jpg", "scribbleSpace": "Expecting early wakeup", "contribution": "if someone asks to share.", "intro": "Software Engineer"}',
  '{"name": "Nilisha F", "place": "South Goa", "waNumber": "919552234801", "email": "f.nilisha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9f8c5fea59b0dd9d258a5ee4dd1247e0370b7c6e2b9c0b6969169b86c30a3dfd/Me.jpg", "scribbleSpace": "Beach bum, enjoy music, need to focus on my health and wellness thats why joined the club. On a weird spiritual journey. Am going to use the morning time for meditation.", "contribution": "I stay in Goa, so i can share everything about the place. My business revolves around coconuts so anyone as crazy abut coconuts as me can share stuff with them. I am also a bachata and salsa dancer", "intro": "Coconut Entrepreneur"}',
  '{"name": "Anshika Mungiya", "place": "Parasia, Chhindwara, MP", "waNumber": "919981310967", "email": "anshikamungiya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0a67072532abc1354ab764547990a65539ea6f687fa579f1dac4bf0c35c6fa43/32223721_FA59_41EB_925B_31D22C06FCD2.jpeg", "scribbleSpace": "I am 24. Reason I want to get up early is to utilise the morning hours to get closer to my professional aspirations.", "contribution": "I am a little bit of everything. I think I can talk about almost everything.. ranging from Psychology, Geography, Politics, Mental Health Issues, Data Science, Computer Science, Music, Art, Vedic Shashtra and so on.", "intro": "Still figuring out!"}',
  '{"name": "Preeti Singh", "place": "New Delhi", "waNumber": "919971508434", "email": "psingh7jan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0f1172a2d1e7fd121a2c85f858025b2a024f98bc40bdd0549159cde2700fe119/Passport_Size_Picture.jpeg", "scribbleSpace": "I suffer from depression and lack a purpose in life. Perhaps that is why I lack motivation to rise up early in life. Yet, I have hope and am willing to do multiple bootcamps if that is what takes for me to build good habits in life.", "contribution": "There was a time when I joined an ashram where we were supposed to wake up at 3 am every morning. I continued this practice for 1 year and can share more about this experience", "intro": "Flitting Bee"}',
  '{"name": "Vaishali Pimprikar", "place": "Mumbai", "waNumber": "917738182064", "email": "vaishali.pimprikar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9d52625839a3c13ba05eeedfdf14a72f897458e6a26f96afe24622c578cc9db6/IMG_20210811_051915__01.jpg", "scribbleSpace": "Trying to inculcate good habits", "contribution": "Learnt not to give up", "intro": "Beginner Guitarist"}',
  '{"name": "Shilpa Saakar", "place": "Powai, Mumbai", "waNumber": "919833740707", "email": "shilpasaakar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/756485fb4f69a474d59b12cb69aa958288226208529532883e72c8f7f7a4c713/IMG_20220208_WA0009.jpg", "scribbleSpace": "www.shilpasaakar.com", "contribution": "Light Language guided meditation", "intro": "Light Language healer"}',
  '{"name": "Leena", "place": "Goregaon, Mumbai", "waNumber": "919819073999", "email": "imleens@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ca16b991d6d03a9b3e38c27cbb322a1b3b9f6f77f3fa5d6dffab4d44f6bfb11b/Leena_Bhatia.JPG", "scribbleSpace": "I am a happy night owl wondering if I can and should become a morning lark", "contribution": "Am a good listener, often credited as an agony aunt. Can share my experiences of corporate life and being a mom to a teenager", "intro": "Craft enthusiast, hands on mom"}',
  '{"name": "Ritusha solomon", "place": "Raipur", "waNumber": "919685306023", "email": "slmn.ritu605@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b930de441f5ef5f2b988be8083f8719edf052ef260c21baf9afecb2df9a0e973/IMG_20220623_010937_297.jpg", "scribbleSpace": "I am a doctor and a pg aspirant wanting to live a fulfilling life full of love, beauty, happiness and fun..\\ud83e\\udd73", "contribution": "Heath related, educational, book quotes, spiritual and inspirational", "intro": "Doctor"}',
  '{"name": "Kaushik Jhamwar", "place": "Sahakarnagar Pune", "waNumber": "919604787539", "email": "kaushikaj82@gmail.com", "photoLine": "https://api.typeform.com/responses/files/85368dcad661c7639d3149a52285a319e8244e455697c403f9381103aef2ae81/Kaushik.jpg", "scribbleSpace": "Interested in a lot of things falling short of time, keen on building my physical, mental, emotional, intellectual & spiritual capabilities", "contribution": "Health awareness, fitness, Financial knowledge", "intro": "Searching Myself"}',
  '{"name": "Vrunda", "place": "Gujarat", "waNumber": "919157160969", "email": "vrundap911@gmail.com", "photoLine": "https://api.typeform.com/responses/files/df1d106d8ba327b95b240095847cd25ad04456aa356235028a607eb60e629e8f/Screenshot_2022_08_30_17_50_07_184_com.google.android.apps.photos.jpg", "scribbleSpace": "Hobbies: Dance, learn new language.\\n\\nexplorer, solo traveller", "contribution": "Can explore in the field of interest in less time", "intro": "CA Final Student"}',
  '{"name": "Meghana", "place": "New Delhi", "waNumber": "918851105815", "email": "meghana22591@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7d89af1c656a57a946caed593730eb160464b8969e86c6841d3783c10b87cccc/IMG_20220524_115628_066.jpg", "scribbleSpace": "Let\'s know once I am regular in the camp :P", "contribution": "Optimism, staying strong in adversities and a lot more may be..haha.", "intro": "Life sciences Researcher"}',
  '{"name": "Yogita Salgaonakar", "place": "Keshavnagar pune", "waNumber": "919923794902", "email": "yogitasalgaonkarpt@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4cea4f3321c3eb504500e2f00a80b6d991e31e63741637c628fa19a6da1d70b2/IMG_20220724_WA0037.jpg", "scribbleSpace": "Gardening and reading", "contribution": "Gardening methods", "intro": "Physiotherapists"}',
  '{"name": "Varun Reddy Sevva", "place": "Kukatpally, Hyderabad", "waNumber": "918500614614", "email": "varun.sevva@iiml.org", "photoLine": "https://api.typeform.com/responses/files/f5f9dce4ca9b2018fe92ebab91d05e4def3a14e211d0809716617caa548a45a2/20210101_163839.jpg", "scribbleSpace": "I work as a HR Professional based out of Gurugram. Married. Occasional Quizzer. US Politics and Cooking are my hobbies. Learning Resume writing in my free time. Planning to host a course on Udemy on Quizzing in the near future. Interested to learn Spanish and know more about Latin American culture.", "contribution": "I can speak about US Political System and US Elections. I can also speak about Esperanto (a language I tried learning recently). I can also run a general quiz if needed. I can also speak about HR in general.", "intro": "Curious Learner"}',
  '{"name": "Aatman Sharma", "place": "Indiranagar, Bangalore", "waNumber": "919599237382", "email": "aatmansharma@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9070fe3b3f3a3d2cbea9dc9ddd2bdbe7e8fccd2bef39b0509c45d0aaf60c117e/21DA1E72_B43E_4019_B17D_E2257BCC50A7.jpeg", "scribbleSpace": "A fun loving dentist who loves to play sports, cook and spend time with family and friends. Issues with getting up early- as a habit. Would want to concentrate on health with the extra time in morning", "contribution": "Oral health, team building, building a healthcare practice, travelling, bikes, sports, cooking, basic personal financial planning", "intro": "Dentist"}',
  '{"name": "Khushboo Rawat", "place": "Lucknow", "waNumber": "918965967526", "email": "khushbu.rawat@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1e056d91248136da6d0591d99119962a0362f3fba7be7e544e675b98753ca882/CFEEAE65_00E2_4C90_ABD7_1A2803F3C50E.jpeg", "scribbleSpace": "Firm believer in power of good habits, looking for this program to bring in more positivity in my routine.", "contribution": "Sports| FMCG | Humour", "intro": "National Level Badminton Player | Selling Soaps at HUL"}',
  '{"name": "Kanika", "place": "Noida", "waNumber": "919599277436", "email": "kanika.arora.2089@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3ee551c3c6d86e90a1a505fb293463a2b38d8e28843537c6a067a92ef2842fa8/20220327_200916.jpg", "scribbleSpace": "My plans and expectations are to achieve my milestones within two years. I have issues in speaking my views to others and time management due to my 10 months old baby. I want to get over my issues anyhow and in any manner.", "contribution": "I have completed my PhD. IN Agriculture and Biotechnology and currently practicing for state govt exam", "intro": "Researcher cum student"}',
  '{"name": "Nimisha", "place": "Surat, Gujarat", "waNumber": "917506493450", "email": "nimmipatel85@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ceb6913815204ec33a8d118ba9c824e8548e0760beab82445f655fe928917fb3/30519C5D_9EE1_475D_8F1A_F46A491AFE79.jpeg", "scribbleSpace": "I like Reading", "contribution": "I m good at Cooking", "intro": "Housewife"}',
  '{"name": "Dia", "place": "Toronto Canada", "waNumber": "14167064961", "email": "deepali.mavani@icloud.com", "photoLine": "https://api.typeform.com/responses/files/2006753eeb3ce242db1ffb3640bb328fe1421721adea8509c75785725a96b3e0/8264E705_F4C8_4FEE_B4F4_8094B5F4ABED.jpeg", "scribbleSpace": "Working on completions", "contribution": "Moving to a new country", "intro": "Action Oriented"}',
  '{"name": "Gauri Gaanu", "place": "Uran, Navi Mumbai", "waNumber": "919930189239", "email": "gaurim.0622@gmail.com", "photoLine": "https://api.typeform.com/responses/files/95c471aa9841a325eba4e691f18110fb2a31a2fb6dd31b67d78f211f52438b49/IMG_20210418_143141.jpg", "scribbleSpace": "Trying to help people get aware about Bhagwadgeeta. I record 2 shlokas with their description & share the audio on social media. Jay Shree Krishna \\ud83d\\ude4f\\ud83c\\udffb", "contribution": "Can share the knowledge of Bhagwadgeeta.", "intro": "Singer"}',
  '{"name": "Akshaya Sivanantham", "place": "Coimbatore, Tamilnadu", "waNumber": "919487713395", "email": "akshayasivanantham@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f9dae58f24c9a17197d6c5601f968372a5619cedc688df77d3843c6e0270034c/IMG_20220806_102903.jpg", "scribbleSpace": "I would like to be consistent but working on determination", "contribution": "Well, I\'m not an expert any field except for my job", "intro": "Data Analyst, yoga practitioner"}',
  '{"name": "Nitin", "place": "Bangalore", "waNumber": "919886505621", "email": "nitinredgreen@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e704dbd0620b15e9418e35ef74d745d233e0f77d63703e4f5b6c132774cc6b9d/IMG_20220715_111329_503.jpg", "scribbleSpace": "Walking, reading, sleeping", "contribution": "Law, politics, philosophy...", "intro": "Lawyer"}',
  '{"name": "Paarul", "place": "Parvati Pune", "waNumber": "919545522293", "email": "parul273@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/14e48b954892d7f078a3e83e61efac4aa3113f31ae82671023e60fae12e533b6/FaceApp_1640337249446.jpg", "scribbleSpace": "They call me Paarul...\\nI am a daughter, sister, wife, mother, illustrator... angry, hungry, eager, anxious, naughty, sad, happy, nervous, frustrated, Irritated, sleepy,\\u00a0 tired, moody, foody, clingy, good egg, bad seed....... and what not...\\ud83e\\udd2a\\nDepending on the situation... \\ud83d\\ude48 \\nIn space.. *I am nothing* \\ud83d\\ude09", "contribution": "How to make kandapohe.", "intro": "Good egg & Bad seed \\ud83d\\ude1c"}',
  '{"name": "Kritika Soni", "place": "Uttar pradesh", "waNumber": "919561555454", "email": "drkritikasoni@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6aa39d15c0dc5e28d1c22f018690d77e187d54b001aa64534d2377425e093575/784C5DAE_FE13_4250_975F_52BF6B1E663D.jpeg", "scribbleSpace": "Hobbies- painting, dancing, cooking", "contribution": "Morning life can be used for spiritual growth", "intro": "Artist"}',
  '{"name": "Kemayahh Karrbhari", "place": "Chinchwad, Pune", "waNumber": "918390258617", "email": "cakimayakhanolkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4b4739203446381e33049d09ab38f1a7720b4b492bb5e389a09ff0986df0cede/5A91680A_1837_474B_BF43_1DA9717C243A.jpeg", "scribbleSpace": "Big time foodie, Interested in Indian Mythology, Planning for my 1st big hike this year", "contribution": "Not Sure!", "intro": "Chartered Accountant |Yoga Practitioner"}',
  '{"name": "Bhavesh Chaudhari", "place": "Nagpur", "waNumber": "917058792289", "email": "bic19075@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c4a95ca7c35c932a7e06ce237286d3deaefe3f2a80c265c7ddf55a73364620b6/IMG_20220805_212039.jpg", "scribbleSpace": "I am a freelancer interested in software development. I believe in enjoying the process and living the journey.", "contribution": "Technical stuff", "intro": "Software Developer"}',
  '{"name": "Shaad", "place": "Majiwada Thane", "waNumber": "918369288435", "email": "aakarint@rediffmail.com", "photoLine": "https://api.typeform.com/responses/files/da4bc08f74a3e95e627c85b132e6fd85d8dc49a257a32409b8216ba1b6993592/Screenshot_20210721_161300_Photos_2.jpg", "scribbleSpace": "Interested in urdu language, learning the same. Want to be fit till the end. Live and Let Live is the moto of my life.", "contribution": "Knowledge about urdu language, shero shayari and of course about Interior Designing", "intro": "Interior Designer"}',
  '{"name": "Ruchi", "place": "Aundh, Pune", "waNumber": "917738079161", "email": "ruch29@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6a6d33700c624ee0eab360991e374385c1b9f5c0d2f4125cdcd0dffb07558f2d/WhatsApp_Image_2022_07_21_at_6.13.18_PM.jpeg", "scribbleSpace": "New beginnings in my life! New city, new home, new people, new work....looking forward to it!", "contribution": "Let\'s paint and craft together! Would love to help out women in distress due to separation. It\'s not the end of the world.", "intro": "Artist"}',
  '{"name": "Abhishek Nandan", "place": "Hinjawadi, Pune", "waNumber": "919930844415", "email": "abhishek.nandan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c3d7e455c243dd56fbdac0e6176564667c51a13b3519eb75a0c516f0e65cc2c8/Screenshot_20220816_220947__01.jpg", "scribbleSpace": "I spend 40% of my day as a revenue generater, a digital transformation consultant and a people manager. In the remaining 60% time, I seek.. I seek to be a good father and friend of my 5 year old, I help my better half manage her 40% time, I seek more on automobiles, I seek the very nature of our existence.. O yes i forgot to mention, I enjoy doing the latter and need more time for it. Hence, I am excited to be part of this group and start on the transformation journey.", "contribution": "My will love sharing my interesting experience of losing 18 kgs.", "intro": "Joy seeker"}',
  '{"name": "Richa Gupta", "place": "Gurugram", "waNumber": "919990223290", "email": "rg.richa1905@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e8205747bd3e1569402ff960c1c081a209991e128c8f3eaa443d29179b0a4430/Screenshot_20220520_125133_WhatsApp.jpg", "scribbleSpace": "I love to be with nature, observe and simply breathe in fresh air. I am an avid learner. \\nI feel low on physical energy because of which I am jot able to get up early.", "contribution": "Painting, Writing", "intro": "Certified ACC coach"}',
  '{"name": "Pranjal kankariya", "place": "Pune", "waNumber": "918329148603", "email": "pkankariya1512@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/5d222a577eb62b4da18c74bd8fac1f15c8945e625e7182b2935f47a769637f53/B2006646_A60E_4994_B52C_13AC92B23676.jpeg", "scribbleSpace": "I love to paint, read and make miniatures, recycle n restore items", "contribution": "I m still learning", "intro": "Physiotherapist"}',
  '{"name": "Shubham Pinge", "place": "Indore", "waNumber": "919977072037", "email": "shubham.pinge123@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4ecbb48d1a75aed514c54fc3cc1f9b0185a258d1aeaae9f8e291821657d6c84e/IMG_20220817_WA0008.jpg", "scribbleSpace": "I like to perform on stage. I also like to discuss business/life problems and understand their root cause.", "contribution": "I can whistle well, mimic people and mainstream celebrities, Talk about personal finance and be up for any random conversation", "intro": "CA Finalist, Public Speaker, Management Aspirant, Aspiring Stand Up Comic"}',
  '{"name": "Charuta", "place": "Sus road, Pune", "waNumber": "919822012622", "email": "charuta.gaikwad@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d6ddcd0642993caa5f95f35c5b7152bbd5c0a916d618bf3ee093311dfb604e6b/IMG20200504175714.jpg", "scribbleSpace": "Love everything that has Soul..", "contribution": "Happiness", "intro": "Life Itself"}',
  '{"name": "Zia@Sea", "place": "Mumbai", "waNumber": "919867748509", "email": "zia@aquasailindia.com", "photoLine": "https://api.typeform.com/responses/files/ce911b75b371e4f48580c1e6a6d9d0d4ee781f66e14faf912ce443180ea3e269/IMG_20220709_WA0017.jpg", "scribbleSpace": "I navigate life\'s seas connecting people to people, exploring horizons. I am a passionate coach and an avid sailor. Aquasail is a pioneering brand nurtured and built with a vision to bring the joy of sailing to India. I care deeply about justice, equality and the environment. Music, books, people and making the world a better place help me flow with the rhythm of the universe", "contribution": "Entrepreneurship\\nLife and business coaching\\nBuilding a brand \\nSailing \\nLeadership\\nSocial impact- gender, education, democracy and secularism \\nJoy Of being present", "intro": "Coach AquaSailor"}',
  '{"name": "Kirteeraj Malkar", "place": "Rajarampuri, Kolhapur", "waNumber": "919405550489", "email": "kirteerajmalkar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5ae79c968ab63c7fab1958ae7e9074fa74236d0a05e09b2758160c8f7176ee4a/Screenshot_20200726_120311.png", "scribbleSpace": "i am passionate Developer, interested in sprituality, meditation and finding meaning of life.", "contribution": "I can share my favorite qoutes, I can share some hacks to wake up early & sleep well", "intro": "Developer"}',
  '{"name": "Sujit Khakale", "place": "Kalyan west", "waNumber": "919820567429", "email": "khakalesujit@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bb7339788ce936502cc5cc72be6b7830cecd037ce8e74cd25e70229ec804cc1d/Screenshot_20191004_094830_Gallery.jpg", "scribbleSpace": "I am a consistent learner.", "contribution": "Petions & Focused on target.", "intro": "Supply chain"}',
  '{"name": "Sandip Shriniwas Kulkarni", "place": "Sinhgad Road, Pune", "waNumber": "919503911294", "email": "sandipkulkarni27@gmail.com", "photoLine": "https://api.typeform.com/responses/files/27d44bb7c6aa7356f9ec5da3f041b214d1729be4ad0d974389b176cb2739004f/WhatsApp_Image_2022_07_31_at_1.35.17_PM.jpeg", "scribbleSpace": "I like reading books, watching movies, listening music and traveling", "contribution": "Mechanical Engineering concepts", "intro": "Assistant Professor"}',
  '{"name": "Omkar Mandke", "place": "Aundh pune", "waNumber": "919860833310", "email": "ommandke@gmail.com", "photoLine": "https://api.typeform.com/responses/files/022bde9cb32f02033ea4e22f0f3d9332896b7aadd92191b36a700b58f25a53c8/IMG_20210620_WA0028.jpg", "scribbleSpace": "Want to exercise daily. Finding it difficult to wake up early.", "contribution": "Tenting, camping and treks.", "intro": "Team Player"}',
  '{"name": "Rahul Sharma", "place": "Sahakarnagar, Pune", "waNumber": "918390876388", "email": "rahul.symantec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a21228e6f6004fdf5eb652c945c2ae0f1a37b252a23d6e0235997fbf7862a575/image.jpg", "scribbleSpace": "Have been working in IT field field since 15 years out of which I was working in Night shift (9:30 PM - 6:30 AM) for last 3 years. This seems to have messed up my body clock. While I am back to regular working hours  now, my expectation is to get into the habit of waking early and going to bed early.", "contribution": "Managing personal finances.", "intro": "People Leader"}',
  '{"name": "Madhura Rale", "place": "Aranyeshwar, Pune", "waNumber": "919860347975", "email": "madhura76@gmail.com", "photoLine": "https://api.typeform.com/responses/files/17aa005a61a9154c4472cff48550c4595d5df2d5316e2ef7844ac04d37fe13a5/Screenshot_20210101_193848_WhatsApp.jpg", "scribbleSpace": "A mad curious person", "contribution": "Not sure... currently in receiving mode", "intro": "Curious biochemist"}',
  '{"name": "Sujata Athor", "place": "Pune", "waNumber": "918830434969", "email": "sujata87athor@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8a4e3aa01921fad8de093d91480e08985a85e1509c23f7fdb3069ff6f0e49dbc/IMG_20220708_183257_943.jpg", "scribbleSpace": "Trekking, gardening and no nonsense \\ud83d\\ude05", "contribution": "My MMA skills, Cloud computing and I can help plan really good vacation.", "intro": "My brutal honesty hurts people more than my MMA skills. More than 100 treks and counting. Psychology, spirituality and mythology awakens my inner child.This is profile of well travelled and we\'ll read women who is not up for drama."}',
  '{"name": "Sanket Badamikar", "place": "Kothrud Pune", "waNumber": "919850991235", "email": "sbadamikar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/df31671d5c1bc3de395588d2538be5f584c44c4c3d5e178a30c606abb9e98dff/ImageN.jpeg", "scribbleSpace": "I like to eat, travel & read a lot. My active life has been suspended for the last 6months, as i had a massive accident. Have started walking independently since last 3 weeks now. \\n\\nFacing a lot of health issues due to lack of physical activity - like insomnia & over thinking. \\n\\nHoping to get my mojo back through this medium!! :)", "contribution": "NA", "intro": "Avid reader, Train enthusiast, Foodie"}',
  '{"name": "Ayush Garg", "place": "East Delhi", "waNumber": "918588859044", "email": "ayush99@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3f6824539c86dce63f54849060154d3cfbff01790fad02d2168fe4207d85fb20/VID_20220826_231734.mp4", "scribbleSpace": "I am interested in Crypto, Defi, languages, trekking, swimming, snooker and running", "contribution": "How to handle numbers and finance", "intro": "DeFi Enthusiast"}',
  '{"name": "Padmini", "place": "Pune& Hyderabad", "waNumber": "919533892008", "email": "sony91.ctm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8768c683646eba642a0ed2257b0404bb83926310ff25a25a47d5307dd6d50dd8/IMG_20220823_200118439_HDR.jpg", "scribbleSpace": "Music and meditation", "contribution": "Many spiritual experiences", "intro": "Digital nomad"}',
  '{"name": "Abhishek Anand", "place": "Delhi NCR", "waNumber": "918800373555", "email": "abhishek.x.anand@iiml.org", "photoLine": "https://api.typeform.com/responses/files/9035e146cb216cdb148787bbc0bdb03f58a48f1f14d29c68fd07eeb9ed32074a/Screenshot_20210101_214311_01.jpeg", "scribbleSpace": "Photography, reading. Love to explore the artistic side.", "contribution": "Will think through", "intro": "Love photography."}',
  '{"name": "Rudresh Upadhyay", "place": "Surat, Gujarat", "waNumber": "918155058584", "email": "rudresh27upadhyay@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d331b4ac0a2af93e6eee957bb3b0ca45c195473a1135c6ff31a5c825c757ed53/gifbg_1.png", "scribbleSpace": "I am an Introvert. I read books, Make graphic and motion design as hobby and like to explore good movies. I am suffering from late night sleeps , i wake up at 8.30 to go to job at 9.30. Need help to set up life to clear goals.", "contribution": "Can exchange books knowledge i have gained, and also i take interest in Religion books", "intro": "Engineer & Graphic designerer"}',
  '{"name": "Vrushali Bhuyar", "place": "Old subhedar, Nagpur", "waNumber": "919325128716", "email": "vrushalibhuyar@gimail.com", "photoLine": "https://api.typeform.com/responses/files/bacfc8afd1223c13b72b0a75df3dcf82053dee04ee7611fa66cd33ecd1eaf17a/16618316934249130286335907488037.jpg", "scribbleSpace": "Nil", "contribution": "Nil", "intro": "Yoga"}',
  '{"name": "Tripti Verma", "place": "Kharadi- Pune", "waNumber": "916375895982", "email": "csverma.tripti@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e4164c6e2e4e8318fc48bd940a782983f01c4c318481d6b4cb1809edf65b8597/07D28A6B_8326_4200_9764_EE30233D9C8B.jpeg", "scribbleSpace": "I\\u2019m here to have healthy life style. I know there are a number of things I want to do, waking up at an early hour is one of them as I know 1 extra hour can make room for something I\\u2019m passionate about.", "contribution": "I love to read books and share my insights on them. I am learning a lot about parallel universe these days. Sometimes when I sit with myself I write some quotes, shayari. And yes celestial bodies amaze me !!", "intro": "Passionate painter"}',
  '{"name": "Kunj Tibrewal", "place": "Mukundnagar, Pune", "waNumber": "919823055900", "email": "kunj@infoworldindia.com", "photoLine": "https://api.typeform.com/responses/files/f205696d5f2217ddb4e533c377a2855d9937d774874f0edd36698df67a270c80/Kunj_photo_884kb.bmp", "scribbleSpace": "I would like to live more disciplined life.", "contribution": "Social Service, Software business", "intro": "Software Businessman"}',
  '{"name": "Pramod Sharma", "place": "Pimple Saudagar", "waNumber": "918879628849", "email": "pskool05@gmail.com", "photoLine": "https://api.typeform.com/responses/files/87727e4bb0e5f9fd0a6b3747ee3668009acbcb62c46547e34c29c48d17864124/16619612327228533033905642765948.jpg", "scribbleSpace": "Love reading english fiction by indian authors, listening to hindi film music, ghazals, singing,", "contribution": "Time management, project management,", "intro": "IT Professional"}',
  '{"name": "Nagaraj Shetty", "place": "Bangalore", "waNumber": "919739989777", "email": "nagaraj.shetty@gmail.com", "photoLine": "https://api.typeform.com/responses/files/81896fd2d84791bafc3840e33ec660b5d5eac7ca2d7c44162a7af40c8d50d116/WhatsApp_Image_2022_08_31_at_10.17.47_PM.jpeg", "scribbleSpace": "A social researcher, a night owl wants to change in to an early bird.", "contribution": "Media, OTT, People research", "intro": "Social Researcher, helping shape OTT"}',
  '{"name": "Sreelatha", "place": "Kochi, Kerala", "waNumber": "919895522177", "email": "Skhaki05@gmail.com", "photoLine": "https://api.typeform.com/responses/files/44c1419a1ef899d968f2656e035d10f53139bdc050022622e8bf13539671ed79/IMG_20220803_WA0076.jpg", "scribbleSpace": "Really, truly believe there\'s more to everyone than they believe there is", "contribution": "Being the Phoenix and rising from the ashes, being the dog and staying true to who I am, it isn\'t over until you decide it is... experiences as one of the first women in the Indian Air Force", "intro": "Work in progress"}',
  '{"name": "Avinash Lanjewar", "place": "Bhandara", "waNumber": "918378803988", "email": "avinash.l.lanjewar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/cca9f145e1ce64b8f9150be9b8f0c2205f60725835e6820fddc464c1d8121c20/IMG_20211106_WA0027.jpg", "scribbleSpace": "Expecting a routine for Early Wake Up do Yoga and exercise.", "contribution": "Not sure right now", "intro": "Software Engineer"}',
  '{"name": "Tehseen Dudani", "place": "Goregaon West Mumbai", "waNumber": "919820185841", "email": "tehseenmehdi@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/5e65eb70a3e8657b8d364f4ccc4816b6208a104b9532e366bbd11bd3ac43483f/9B68C08D_BC06_4307_B14D_C3E45EDBC860.jpeg", "scribbleSpace": "Reading, travelling, working on health and fitness and healing", "contribution": "How to reverse diseases like diabetes etc through nutrition, power of affirmations, forgiveness, self love, steps to living a healthy life, dealing with elders, children, spouse etc", "intro": "Health Trainer"}',
  '{"name": "Suyash Nagayach", "place": "Bavdhan, Pune", "waNumber": "919890005923", "email": "suyash.nagayach@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9d34718becfbb22cf350392afe2e89078ccc5f860c2c5f16ede299769db9d973/IMG_8329.JPG", "scribbleSpace": "I am avid sports lover, like reading books (mostly non-fiction) and photography is more than merely an interest. The biggest expectation in life is to continuosly become better version of self. Being a night owl all my life, I decided to take up the challenge to wake up early and whoa I have somehow conquered it and discovered about myself more than ever before. Nights are wonderful but believe me Mornings are pure bliss.", "contribution": "I can offer sharing different perspectives from the world of sports. Also, I would like sharing the philosophical poetry during the daily interactions.", "intro": "Pragmatic Believer"}',
  '{"name": "Nandhitha", "place": "Bangalore", "waNumber": "919790885665", "email": "nandhitha.hariharan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e12cf9bb3bb375cfcb589ffe3cd1c9c95c85542490aac3a8b36dfe4a1df62a42/FBC6AE01_5944_431B_8D29_4F71162400FC.jpeg", "scribbleSpace": "Writer", "contribution": "Writing", "intro": "Writer, marketer"}',
  '{"name": "Pooja Rathi", "place": "Rasta peth, Pune", "waNumber": "918983307766", "email": "poojasrathi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/096dcc6328752a21abee152a96d3f864bb84b3d09c6ddd7ef31e5ce819a9f5f6/4E4EC89A_EEE6_486D_B773_870D86390D01.jpeg", "scribbleSpace": "I am a night person, got great difficulty in waking up early, in the process of getting a divorce, going to work on myself now", "contribution": "Not much I can help anyone with", "intro": "Punctual Pooja"}',
  '{"name": "Pranali Khanolkar", "place": "Andheri West , mumbai", "waNumber": "919820210520", "email": "pranalik@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/4a5e61e4b1bf79ca1f93491cb5503f457e339fd46624b597b7ec1aa7cba3c0fc/IMG_20220724_WA0006_2.jpg", "scribbleSpace": "Have fun ,enjoy life , travel around the world ,earn lots of money ,dive deep into spirituality...be happy without a reason", "contribution": "Stock Market trading and investments", "intro": "Traveller by heart"}'
];


var julyData = [
  '{"name": "Parul Deshmukh", "place": "Treasure Park, near walvekar lawns", "waNumber": "919545522293", "email": "parul273@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/976df398aa7a2caa37faceb9d0123df37cb3512afcddd0b02c814c85a678be09/105BE85E_6E2F_4454_8A01_7058C292D165.jpeg", "scribbleSpace": "Love to try and learn new spiritual practices.", "contribution": "Painting", "intro": "Illustrator"}',
  '{"name": "Bhoomica Asnani", "place": "Thane Mumbai", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/87cfbc20e576511150a444636bf9a4dbf47f320c230b669851973a5b31ea768e/IMG_20210524_093922.jpg", "scribbleSpace": "_", "contribution": "-", "intro": "Hopeful dreamer"}',
  '{"name": "Bhoomica Asnani", "place": "Thane Mumbai", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7e10c78d7ac5ecbec1e44dfefd08dd7e754012345f5fc9ad3a2c6ac68756cf93/IMG_20210328_190020.jpg", "scribbleSpace": "I am an academician by profession. I like meeting new people and learn more about the joys of being alive.", "contribution": "Experiences of being an academician. Difficult teenagers, lazy youngsters, helicopter parents", "intro": "Seasoned Woman"}',

  '{"name": "Pritam Potghan", "place": "Moshi Pradhikaran, Pune", "waNumber": "919075002244", "email": "potghanpritam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dde3eb33d351bbc38a3b8f227d6ce3a93b1df66564930ca5bd87fe24aacbba2d/IMG20201018173557.jpg", "scribbleSpace": "I love reading spiritual and self help books, writing in bullet journal, meditating. I like psychology and mythology. I am ambitious to be a Surgeon, a Doctor, to save lives.", "contribution": "The power of reading, Meditation, how to deal with depression, Guide to karma, study tips, things i do in my me time.", "intro": "Aspiring Medical student"}',
  '{"name": "Chetan Kapadnis", "place": "Ambegaon Budruk Pune", "waNumber": "919766291184", "email": "kapadniscv@gmail.com", "photoLine": "https://api.typeform.com/responses/files/01e6e9f22f99e8caefe82a72b0d73aaea947492e54c349ebc24e209c713d758e/20220714_163739.jpg", "scribbleSpace": "I like reading life and entrepreneurship related books. I would like to work on life design for better life", "contribution": "Project Management, Strategy Execution, Sustainable business", "intro": "Professor"}',
  '{"name": "Priyanka Khade", "place": "Nerul, Navi Mumbai", "waNumber": "919969922023", "email": "priyasvhat@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ad8e62b1faac90af8bbfb05f7e2a5f8180c580f5b6dd319251b028d467cd96ba/IMG_20220319_WA0009.jpg", "scribbleSpace": "I did my graduation in 2018, since then preparing for govt exams but couldn\'t achieve anything yet. Now I am getting married soon but I am jobless and being dependent on others is not what I like. Continuing the preparation but for that self management is very much required and that\'s why I am joining 5 AM club.", "contribution": "I like creative writing, I do write few things of my interest in marathi language. I ride bike as well as car.", "intro": "Graduate"}',
  '{"name": "Mitravinda Ray", "place": "Bommanahalli Bangalore", "waNumber": "917259891891", "email": "mitravindaray@gmail.com", "photoLine": "https://api.typeform.com/responses/files/69d7590207ab88fabe6473e48cb6cca801e99671a4a37408e152a7f7e9befd0f/20220712_195029.jpg", "scribbleSpace": "Independent, strong, minds her own business, loves to swim and lift heavy weights.", "contribution": "Mindfulness, conscious communication, self awareness and crucibles", "intro": "No BS"}',
  '{"name": "Vineet Midha", "place": "New Delhi", "waNumber": "917838281112", "email": "vineet.midha@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/9ebc4e96a357024a243ee0c6380bcd88ca01a946542305cf925c333ebc657bc5/a6e933887333_16592562755276026258935877452413.jpg", "scribbleSpace": "I like Running and Gym", "contribution": "Not sure", "intro": "On a journey from Mr. Fat to Mr. Fit"}',
  '{"name": "Harshad", "place": "Chennai", "waNumber": "919445639144", "email": "harshadbala@gmail.com", "photoLine": "https://api.typeform.com/responses/files/59689439852910273ebc2dc1cb9be9505f44e063be594335e1a0855537c89e55/IMG_20210925_165641.jpg", "scribbleSpace": "-", "contribution": "-", "intro": "Hodophile & CA Aspirant."}',
  '{"name": "Sanskar Kale", "place": "Hinganghat", "waNumber": "917709805121", "email": "sanskarkale92@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f2f66e78bf332935a50b963b2c80ebb07a342c0c615e5bd31bcf149458c1f072/c6e03c9803f10e6cb0f26b340646d438.jpg", "scribbleSpace": "I like to draw, sketch and football", "contribution": "Drawing I guess", "intro": "Artist"}',
  '{"name": "Harsha", "place": "Bangalore", "waNumber": "918929800717", "email": "singh.harsha15@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7ea0031e1d514535300f793122a4a00ba09d70ee18a3b3a07a9af41a6929dcd2/C189AAEC_A643_4EDF_AAC2_73465D6118EA.jpeg", "scribbleSpace": "Travelling exploring places, listening music and in to spirituality", "contribution": "Depends, what i can be of any help to anyone", "intro": "HR"}',
  '{"name": "Monica Mehta", "place": "Aundh Pune", "waNumber": "919823033440", "email": "monica_c_mehta@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/5bef545d46dcdc8e682d50dc892798495494c0fab5a1bc41f5fb76a6857efc7b/9011E235_9CDD_4E9E_A0EA_EC03EBB7F127.jpeg", "scribbleSpace": "I love dogs, walking, dancing, listening to music", "contribution": "How to Listen is what I can share", "intro": "Life Coach"}',
  '{"name": "Girish Khetan", "place": "Prabhat Road, Pune.", "waNumber": "919822614595", "email": "khetangirish@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5b7b55fd1a5c090907499d5a36faae547ea896fde882f456db1cfeeba265f07d/FB3F448A_D2BF_49D0_8E14_D02F578EB7F5.jpeg", "scribbleSpace": "I like to be healthy and fit. I am interested in running, swimming, cycling, trekking. I did \\u201cEverest Base Camp Trek\\u201d in the year 2017. Before that I have also done half marathon 21 kms run. Later I became complacent and my current weight is 102 kgs. I want to reduce it to 85 kgs. My ambitious goal is to run a full marathon.", "contribution": "Health & Fitness related topics.", "intro": "Health and Fitness lover."}',
  '{"name": "Vipashyana", "place": "Panchkula", "waNumber": "919502034971", "email": "vipashyanaupasika@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2b5b6a791c25cca665ed3a4daba24ff609b6a857a2493147d4f15cc31ac8c3d1/IMG20220728195203.jpg", "scribbleSpace": "I want peaceful life.", "contribution": "Nothing", "intro": "Home maker"}',
  '{"name": "Shrishti Prabhu dessai", "place": "Goa", "waNumber": "918975591646", "email": "prabhudesaishrishti@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c00e7393af00f15a7df440f00f928b6940f0d58dc7192ceaa5287f6df35f4046/IMG20211104175416_01.jpeg", "scribbleSpace": "Just being ME", "contribution": "Life lessons", "intro": "Influencer, content creator, blogger, Master of pharmacy, Goan"}',
  '{"name": "Vineet Midha", "place": "New Delhi", "waNumber": "917838281112", "email": "vineet.midha@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/c1a6a7a2bdfca287cc361c143283ca6a91c5b29eb9d53badbf4bc0f8d48a8f73/16592562755276026258935877452413.jpg", "scribbleSpace": "Running and gym", "contribution": "None", "intro": "Introduction dene layak kuch nhi hai"}',
  '{"name": "Moin", "place": "Vatva ahmedabad", "waNumber": "918000510580", "email": "kazimoin02@gmail.com", "photoLine": "https://api.typeform.com/responses/files/031bdcf060e2f6c31c4924e6c780e932a940e2089d825038e82a444d98064c62/_KEN1749.JPG", "scribbleSpace": "Financial planning", "contribution": "Risk free life", "intro": "Yoga"}',
  '{"name": "Ishita Dutt", "place": "New Delhi", "waNumber": "919711071367", "email": "ishitacutiepie@gmail.com", "photoLine": "https://api.typeform.com/responses/files/00549b6011d127e77e3844f7d70dbd29b3d6fa19f754a66a48bf6e36a094f86a/IMG_20220428_WA0028.jpg", "scribbleSpace": "I love to read, and to talk about books and movies. I want a simple life with meaningful experiences, starting with enjoying early mornings.", "contribution": "I have a background in Economics and an interest in Literature, so can share stuff related to that", "intro": "Art Lover"}',
  '{"name": "Tong", "place": "Bangkok Thailand", "waNumber": "66863157331", "email": "entechdevice@yahoo.co.th", "photoLine": "https://api.typeform.com/responses/files/42363db8033149283a6e79026eaea8817a8d0664ba68d60dd478949722b14c41/8347D965_E9C7_4622_BFDA_C3E99770EAC9.jpeg", "scribbleSpace": "Yoga, Animals lover, Being in nature, Traveling, Reading, Good Relationship, Healthy, Trying to be better\\ud83d\\ude0c", "contribution": "Food, Yoga, Working out, Traveling.", "intro": "Finding myself"}',
  '{"name": "Shanti lal", "place": "Anand", "waNumber": "919709537307", "email": "shanparmar74@gmail.com", "photoLine": "https://api.typeform.com/responses/files/17a6ecbd4463ca73783914c41f70c69cf70533591991253669b66af177bdbf91/IMG_20220729_WA0013.jpg", "scribbleSpace": "Trainer, Mentor, Youth Empowerment, Reading, Sports, Nutrition, Public Health, Career Counselling", "contribution": "Career Analyst, Public Health & Nutrition, Youth Empoweement", "intro": "Empowering Youth"}',
  '{"name": "Sabyasachi Mohapatra", "place": "Bangalore", "waNumber": "919742480130", "email": "sabymohapatra@gmail.com", "photoLine": "https://api.typeform.com/responses/files/08b9c1f815679ae42b288c125f3255b1251d6ecceef9c94cf94e9ca2bfc010cd/16592570411837689164609740055854.jpg", "scribbleSpace": "Love to travel", "contribution": "Not sure :)", "intro": "Product manager"}',
  '{"name": "Ravi Dodiya", "place": "Surat (Gujarat)", "waNumber": "916353878821", "email": "Dodiyaravi64@gmail.com", "photoLine": "https://api.typeform.com/responses/files/752d6d337dc5457b8fbc1aad500b11bb4a456e7951b6dad7a9295f07adee3625/20180407_143714.jpg", "scribbleSpace": "I love to unplan traveling. I also love to connect with people, I love to share top secrets with strangers because they never expose them. let\'s get connected and help to grow the society together.", "contribution": "I can help to grow your business with my experience in especially retail business or brand building. I can help bring a buddy to help reach some goals.", "intro": "The Businessman"}',
  '{"name": "Ayushi Mudgal", "place": "Kanpur", "waNumber": "918979243362", "email": "shaayu3@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea2d2fb9ed1c839a38eb2b0b6517dd30cd92122978028eff0d5b7f6dd38edead/8B2B4459_6755_4225_8E2A_82D7C70E592C.jpeg", "scribbleSpace": "I like to travel and explore new places, meet new people and love to experience new things", "contribution": "I am a medico I have done MBBS", "intro": "Cheerful doc"}',
  '{"name": "Poonam Mandke", "place": "Aundh Pune", "waNumber": "919970921230", "email": "poonammandhyani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b66c5a06e25058417b5734fd576d53c4035a3865a07cf274fc723fe26ca3865d/IMG_20210717_095819.jpg", "scribbleSpace": "I like music and dance, like being healthy and get people healthy at mind body and soul. I wish to help India get more healthy and generate more work opportunities for women.", "contribution": "I can help upcoming entrepreneurs with brainstorming for ideas or execution plan and strategy. I can help being a buddy to help reach some goals. Basically accountability partner.", "intro": "Health buddy"}',
  '{"name": "Gargi Desai", "place": "Baner pune", "waNumber": "919021012997", "email": "gargi.urbounce@gmail.com", "photoLine": "https://api.typeform.com/responses/files/45466bfe40fd5cd9c813913bb2d41491ab7ecbb418622eb6cdd7c4fdc6f6fc98/1ECD005A_6D97_4399_9142_2A7056C55F00.jpeg", "scribbleSpace": "I love to dance, in fitness also I am expert in Aerobics and Zumba. Love to meet new people and love to talk", "contribution": "Not much", "intro": "Fitness expert"}',
  '{"name": "Anju Manoj", "place": "Calicut, Kerala", "waNumber": "919496611628", "email": "anjumanoj.knz5@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6cb47009ca5f7d98789810a4724c1aaae248f526a49f1a37cab1b41159c26831/ANJU_MANOJ.jpg", "scribbleSpace": "I hope this platform helps me be disciplined in life to cherish the serenity of early mornings.", "contribution": "Living in the moment is the best one can ever do, if you can earn a little more of those moments by waking up a little early why to lose the chance to gain it!! I am someone who loves to be in the moment.. that is something i can share too!!", "intro": "In pursuit of happiness!! I\'m a CA Final student trying to be better than yesterday!!"}',
  '{"name": "Ranjini", "place": "Bangalore", "waNumber": "918884640044", "email": "ranjini_68@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/151b6556e6789d174db98609ab5b8b88c177678815356b643d88989312f4c5dd/20211228_102758.jpg", "scribbleSpace": "Gardening, meditation,self development , singing , trekking", "contribution": "Songs, yoga teaching and tips", "intro": "Singer, yoga practicioner"}',

  '{"name": "Aditya Singh Jamwal", "place": "Gurugram", "waNumber": "919599938758", "email": "captadityajamwal@gmail.com", "photoLine": "https://api.typeform.com/responses/files/cbb8b20215e20b2efecf4064a36d729d884474e9c44b4c2c8ac354cb14b83c97/WhatsApp_Image_2022_07_31_at_7.14.55_AM.jpeg", "scribbleSpace": "I am a fun loving person, like to travel and make new friends. I would like to work on my time management and stress control.", "contribution": "I have sailed around the world as a seafarer and have seem may countries, interacted with people of varied cultures. Hence I bring with me a good understanding of people and cultures around the world. Additionally, due to my work, I bring with me knowledge of the Shipping and Maritime trade.", "intro": "Outdoor enthusiast"}',
  '{"name": "Swathy", "place": "Coimbatore Tamilnadu", "waNumber": "919600428651", "email": "swathykity@gmail.com", "photoLine": "https://api.typeform.com/responses/files/55bbf548381402325839cdbbdef4b0c09df4f05c93783a31aaa3ea8eadb05226/IMG_20210220_153922.jpg", "scribbleSpace": "My hobbies are reading book", "contribution": "Nil", "intro": "Teacher"}',
  '{"name": "Sarita Purswani", "place": "Kharadi, Pune", "waNumber": "919158886552", "email": "", "photoLine": "https://api.typeform.com/responses/files/2b2a7cceed9d2c4b01282c15aaa2c5f9e103e9b8cd99b9127b5e687529acb1ed/576F6CCC_9160_471D_AFCE_415C68AA17AF.jpeg", "scribbleSpace": "Not sure", "contribution": ".", "intro": "Educationist"}',
  '{"name": "Poonam Jain", "place": "Aurangabad", "waNumber": "917276024515", "email": "poonam1409jain@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1cbaf18f1c04fd9079790a3e3c6f3d1d60e71ed78e3740894af28cecd39ccf41/DC54FC7F_BFA8_49E2_B70A_63D6EEE1F926.jpeg", "scribbleSpace": "Expectations: want to take control over myself, over my thoughts and have mental peace and try to be consistent in whatever i want to achieve. \\n\\nPlan : Currently in a job from last 1.5 years i am happy there but wanted to build something of my own", "contribution": "I did a recent trip to Jammu Kashmir which was a eye opener for me to step out of my comfort zone and do something different i can share all about that also if in case anyone is having any issue with computer science languages i will try to help\\ud83d\\ude05", "intro": "Software Engineer"}',

  '{"name": "Saurabh Srivastava", "place": "Gurgaon", "waNumber": "919910977255", "email": "saurabh.itbhu2k@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1b3830c27df5e2d2744a4285923d7157f61c5e2d56df654dee15113d92d033fd/IMG_20220104_101445.jpg", "scribbleSpace": "A simple family man.", "contribution": "Marketing and product Gyan for professionals and can discuss books and Hindu mythology", "intro": "Striver"}',
  '{"name": "Sivanathan", "place": "Chennai", "waNumber": "919535109460", "email": "sivanathanbbe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/93ecb6a58ad49333a26142c2f63ed5f28390713e88cdb8001638de5dd542eef4/IMG_20220316_123005.jpg", "scribbleSpace": "Working on self improvement", "contribution": "NA", "intro": "Software engineer"}',
  '{"name": "Mohit Relwani", "place": "Pimple Saudagar ,Pune", "waNumber": "919808537037", "email": "mohitmeet2000@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b4b88f88bfd5323d77fd6ca3f482687fc0d4496762ac022d62e93bd3327354f1/PXL_20220303_151019327_2.jpg", "scribbleSpace": "Here to become a morning friendly person.", "contribution": "Yet to be discovered \\ud83d\\ude1b", "intro": "IT Professional"}',
  '{"name": "Suma Kanojia", "place": "Mumbai", "waNumber": "919867200682", "email": "suma872@gmail.com", "photoLine": "https://api.typeform.com/responses/files/48caa869d042b340cf08b64cb17edcffd92c61d60776e6242a6ebbe6ccc37d00/IMG_20210316_193407.jpg", "scribbleSpace": "I am cancer survivor and I love to travel dance and be happy", "contribution": "Will think of", "intro": "Corporate Professional"}',
  '{"name": "Vishal", "place": "Hinjewadi Pune", "waNumber": "919420999847", "email": "pawarvishal98@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1d7c2fa5a166ded984613be449ec1920b39dc923f77be542e7d371e9980c2bd8/2022_01_09_11_23_06_782.jpg", "scribbleSpace": "I want own my morning, so to be more productive", "contribution": "CAD , PLM,", "intro": "Plm consultant"}',
  '{"name": "Dr. Shreya sharma", "place": "Delhi", "waNumber": "919370128562", "email": "shreya.avril@gmail.com", "photoLine": "https://api.typeform.com/responses/files/10e9620991e63b9e0c58850591e7fdace9aa6cd1391eddebf664775d7a174968/IMG_20220402_130905_235.jpg", "scribbleSpace": "I love spending time in nature", "contribution": "About Ayurveda", "intro": "Ayurvedic Doctor"}',
  '{"name": "Sreevidya", "place": "Kerala", "waNumber": "917373040330", "email": "sreevidya.sumeru@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4ff08ae49b887befc0782c4db111a30601842e51c7a4da7c53135c861a650aac/Screenshot_2022_04_30_21_24_33_84_99c04817c0de5652397fc8b56c3b3817.jpg", "scribbleSpace": "Drawing, spirituality, Traveling", "contribution": "Art and drawing", "intro": "Artist drawing"}',
  '{"name": "Pankaj Mishra", "place": "Wakad, Pune", "waNumber": "919167191835", "email": "mishra.pa@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0152b92c0322f9a84cfddff18f181bd07aef8785168138ac75451e308ae85826/_TP_9259_Edit.jpg", "scribbleSpace": "Focus on doing daily exercise, plan for the day", "contribution": "Indian cities - my experiences", "intro": "Tennis player, ML enthusiast"}',
  '{"name": "Sandeep Karande", "place": "Brahmand, Thane", "waNumber": "919619903224", "email": "karandesandeep@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f4b2a91f5b54c36292644395990d8212b5d288584c594bc2c2af88fe5003968a/DSC_9324.jpg", "scribbleSpace": "I enjoy trekking, bikes rides and nature conservation", "contribution": "How treks can help you explore a new self.", "intro": "Nature Lover"}',
  '{"name": "Janardan Suryavnshi", "place": "Bardoli Surat , Gujarat", "waNumber": "919825362186", "email": "janardansuryavnshi@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/f65efadbb8e2c558efe19443d6b91f2e67992a7a68e8ab4404f7d09d600c9ef7/FB_IMG_1554922406834.jpg", "scribbleSpace": "Plans", "contribution": "No", "intro": "Nature"}',
  '{"name": "Ranjini", "place": "Bangalore", "waNumber": "918884640044", "email": "ranjini_68@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/151b6556e6789d174db98609ab5b8b88c177678815356b643d88989312f4c5dd/20211228_102758.jpg", "scribbleSpace": "Gardening, meditation,self development , singing , trekking", "contribution": "Songs, yoga teaching and tips", "intro": "Singer, yoga practicioner"}',
  '{"name": "Bhavna", "place": "Noida", "waNumber": "919718070206", "email": "bhavnasumani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/043650bd2e2fc2752ff2b2476ac5c15effe4d93d09a5fc4da9f089cf9ee7c575/20220203_210401.jpg", "scribbleSpace": "Spirituality", "contribution": "I like to talk about soul consciousness", "intro": "Rajayoga"}',
  '{"name": "Anu Prabhakar", "place": "BARC, Mumbai", "waNumber": "919820160969", "email": "prabhakaranu93@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ebebb56b65f4318d9b91334a195d44e96bf409bb27600dbe4442f809320d3d7c/IMG_20200412_WA0004.jpg", "scribbleSpace": "With the start of this program I want to be make finish maximum of my non office duties in morning such as exercise, yoga. \\nPlan to journal my daily morning update for these 21 days. \\nGoal to be more morning friendly", "contribution": "Maybe some exercise tips", "intro": "Ambivert, love nature, athlete"}',
  '{"name": "Umesh Kedari", "place": "Katraj Pune", "waNumber": "919850355691", "email": "umesh.kedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72e716e345216a58fdd9f46ef50ef577fba33868fb139f3cea7bbaeb285ae1e1/IMG_20200521_094042.jpg", "scribbleSpace": "Listening Indian classical music, Gardening", "contribution": "Experience", "intro": "Mechanical Engineer"}',
  '{"name": "Smita Rudrake", "place": "Bavdhan Pune", "waNumber": "919011322458", "email": "smitarudrake@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3d845229e34181242c641a026a47cc645c6958c4790251070f15bfaaa00dae26/04E85F86_BADE_4063_8390_A4A33BC9C5C4.jpeg", "scribbleSpace": "I am a single mother of 2 yr old \\u201call day dancer\\u201d, creative by nature and expecting support for long term \\u201cearly waking\\u201d habit from this group.", "contribution": "Painting", "intro": "Yoga practitioner"}',
  '{"name": "Divyesh Doshi", "place": "Worli - Mumbai", "waNumber": "919820298215", "email": "divyeshdoshi5@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ebf277ab27f40f2c714d1b0dfbee89153c68ab27e9c25b4a19ba24cc3ed1bf08/0B9CADE5_F02F_47C0_84C5_6716FBCACF6F.jpeg", "scribbleSpace": "Trying to find consistency", "contribution": "Not sure", "intro": "Lawyer"}',
  '{"name": "Jibin", "place": "Bengaluru", "waNumber": "918105289830", "email": "jibzjibz@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fd0c260b3999dab16c77a3a939107f746b1831f196428d31a3208fba51525a10/IMG_20220517_110720.jpg", "scribbleSpace": "Interests : Gym, Football , Badminton, Riding my bike.\\nHobbies : learning football dribbling & scoreing skills\\n\\nExpectations : Focus on office work , productivity increase . work to match up with play .\\nBeing highly productive along with highly active lifestyle.\\n\\nMajor Issues : Office Work related procastination and resulting overwhelming feeling stress & anxiety. \\n\\nNote :\\nI am Non religious. Freethinker / rational / atheist / Maverick etc some words that match more. I try remain silent on religious ideologies discussion if it appears anywhere to avoid conflict as it doesn\'t make any sense for me.", "contribution": "I have dropped weight 7-8kg several times quite fast. Can share some tips. \\n\\nHow to do powerful Badminton smash.", "intro": "IT Professional, likes playing sports."}',
  '{"name": "Janardan Suryavnshi", "place": "Bardoli,Surat, Gujarat", "waNumber": "919825362186", "email": "janardansuryavnshi@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/4b64eaa64c0fcb00be229669f116da6023c5308002c08c4489b1dfb764b1244c/IMG_20220428_193718.jpg", "scribbleSpace": ".", "contribution": ".", "intro": "Reader"}',
  '{"name": "Saurabh Srivastava", "place": "Gurgaon", "waNumber": "919910977255", "email": "saurabh.itbhu2k@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1b3830c27df5e2d2744a4285923d7157f61c5e2d56df654dee15113d92d033fd/IMG_20220104_101445.jpg", "scribbleSpace": "A simple family man.", "contribution": "Marketing and product Gyan for professionals and can discuss books and Hindu mythology", "intro": "Striver"}',
  '{"name": "Bhavna", "place": "Noida", "waNumber": "919718070206", "email": "bhavnasumani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/043650bd2e2fc2752ff2b2476ac5c15effe4d93d09a5fc4da9f089cf9ee7c575/20220203_210401.jpg", "scribbleSpace": "Spirituality", "contribution": "I like to talk about soul consciousness", "intro": "Rajayoga"}',
  '{"name": "Suma Kanojia", "place": "Mumbai", "waNumber": "919867200682", "email": "suma872@gmail.com", "photoLine": "https://api.typeform.com/responses/files/48caa869d042b340cf08b64cb17edcffd92c61d60776e6242a6ebbe6ccc37d00/IMG_20210316_193407.jpg", "scribbleSpace": "I am cancer survivor and I love to travel dance and be happy", "contribution": "Will think of", "intro": "Corporate Professional"}',
  '{"name": "Aatman Sharma", "place": "Indiranagar, Bangalore", "waNumber": "919599237382", "email": "aatmansharma@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9070fe3b3f3a3d2cbea9dc9ddd2bdbe7e8fccd2bef39b0509c45d0aaf60c117e/21DA1E72_B43E_4019_B17D_E2257BCC50A7.jpeg", "scribbleSpace": "A fun loving dentist who loves to play sports, cook and spend time with family and friends. Issues with getting up early- as a habit. Would want to concentrate on health with the extra time in morning", "contribution": "Oral health, team building, building a healthcare practice, travelling, bikes, sports, cooking, basic personal financial planning", "intro": "Dentist"}',
  '{"name": "Adrija Mishra", "place": "Bhubaneswar", "waNumber": "918826053248", "email": "mishra.adrija@gmail.com", "photoLine": "https://api.typeform.com/responses/files/12dfd9631299c5f31316a02274b478e922c9ead64c5545eaf8ca30fcac908eaf/5C9857FE_CAA6_43BB_AED0_9EDF82FEECA3.jpeg", "scribbleSpace": "I like sketching and reading.", "contribution": "I\\u2019ll think this through", "intro": "Lawyer"}',
  '{"name": "Sagar Sudam Shinde", "place": "Chanchwad Pune", "waNumber": "919822988741", "email": "shinde.sagar1509@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2fe5d86c2df15b73d8a7bd44c854633a4649f6d503384838e555c54172614c19/FB_IMG_1612714794201.jpg", "scribbleSpace": "I am an Software Engineer, Runner, Trecker, fitness crazy. Striving for making best version of my self", "contribution": "Fitness tips", "intro": "An Engineer Runner"}',
 
  '{"name": "Insiya", "place": "Vadodara", "waNumber": "919909010535", "email": "insiya.petiwala@gmail.com", "photoLine": "https://api.typeform.com/responses/files/41cbc0a4d961a1a2830f4e40eac277bc50dd58d049ce9063f7b7364e9176ebc3/IMG_20220219_210314.jpg", "scribbleSpace": "Trying to take control,", "contribution": "Power of gratitude", "intro": "A talkative introvert"}',
  '{"name": "Tanvi sujit kolte", "place": "Dombivali", "waNumber": "919967771914", "email": "tskolte380@gmail.com", "photoLine": "https://api.typeform.com/responses/files/336516cffa3560bc30de4ad205100ff20382af7888a7babe7f72f41a371b3993/IMG_20210318_WA0013.jpg", "scribbleSpace": "Dance", "contribution": "Insurance", "intro": "Classical dancer, yoga teacher"}',
  '{"name": "Kavitha Reddy", "place": "Hyderabad", "waNumber": "919177063544", "email": "yeruvakavithareddi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3bea44dda7357c6d900eec31029c3507c037e162824c51603e3a38d2267e5595/Screenshot_2021_0630_190954.png", "scribbleSpace": "Interested in knowing new things, like playing sports, good @cooking", "contribution": "Can share life experience s", "intro": "Interested in knowing new things, like playing sports, good @cooking"}',
  '{"name": "Veronica Patil", "place": "Narhe", "waNumber": "919156623110", "email": "vrncpatil@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8dc504045d0bab363e7de3c0d62aeafec25290b317205cc616cbab8f97850db3/IMG_20220320_142658.jpg", "scribbleSpace": "Hi everyone, I like to be creative in life and live everyday life in colorful way. I am an ambivert, i like to have productive conversations.", "contribution": "NA", "intro": "Artist,Interior designer,Fitness enthusiast"}',
  '{"name": "Atul", "place": "Powai mumbai", "waNumber": "918888861848", "email": "atul.parab@outlook.com", "photoLine": "https://api.typeform.com/responses/files/f706bd1ef97099e5e6cf0a6d95df5db875c3c83633885eaa71c611794df26ca5/IMG_9711.jpg", "scribbleSpace": "Believe in technology and finding inner soul", "contribution": "High tech communication", "intro": "Learning tech"}',
  '{"name": "Neha Chaurasia", "place": "Mumbai", "waNumber": "919082967356", "email": "optomchourasianeha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/114c31ad7bb3555df94d0ed03af4f030bddf79af8db19efa6d6da1bd65b53694/7E55CAFF_E30F_4701_A87A_026E62608C5E.jpeg", "scribbleSpace": "Hi I am an optometrist by profession. I am currently pursuing my higher studies in eye care. I am an enthusiast, my hobbies are dancing, reading and exploring new places and food joints", "contribution": "I am good at my work on eye car\\u2026 I am a good trainer, and have excel in public speaking", "intro": "Eye Specialist"}',
  '{"name": "Shilpa", "place": "Wakad pune", "waNumber": "918554855333", "email": "shilpa.palrecha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8e2f8fbb978fd0e35bc24056fd4aaa83ca25477b260c4c09fc7f182bf9beeeeb/IMG_20211018_203544.jpg", "scribbleSpace": "I, believe in live n let live. Just want to add discipline in my life n stick to my plans to achieve my set goals.", "contribution": "Can help them in setting up business and be a friend who believes in growing together.", "intro": "Company secretary"}',
  '{"name": "Sripriya", "place": "Nizamabad , Telangana", "waNumber": "919515204080", "email": "sripriyamottala@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f5e4f2407ea3e3b145ef7562a55110fe5bc98c6cf8ee34d0031fc4ae7aa6a7ea/54892438_1BD9_408F_8E32_A1C4C178276F.jpeg", "scribbleSpace": "Am very reserved type of person .", "contribution": "I can speak about my early times in setting up my new startup and how much it takes to over come all the circumstances to be successful.", "intro": "Entrepreneur!"}',
  '{"name": "Anusha Yeruva", "place": "Hyd, Telangana", "waNumber": "917036449449", "email": "anshuyeruva@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e45a8e63cc630139af3f0d8f9ef450a6d0f497d93bf5fc0b078d7605cfea4efe/Screenshot_20210521_195038_Photos_2.jpg", "scribbleSpace": "I am intrested in sustainable living. I love nature walks, take fresh air and organic diet, which helps me to be fit and healthy. I am looking forward to know myself and my intrests with the help of this club.", "contribution": "I can discuss on sustainabke living and its future benifit", "intro": "Yoga practitioner and a minimalist"}',
  '{"name": "Deepika", "place": "Race course, Coimbatore", "waNumber": "919566535133", "email": "deepika.dks@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9d6bdb07c85edd9fc788f16e694a8043e8aaa730d2574b6e385a5662e42833be/Screenshot_20220430_214000.jpg", "scribbleSpace": "Love trekking , camping . Still unemployed . Trying to crack a exam and fly high.", "contribution": "I\'ve done a mountaineerg course in Himalayas.", "intro": "Like to ride horses ,jump ropes and reading books ( no choice as prepping for a tough exam)"}',
  '{"name": "Madhav Agarwal", "place": "Lashkar, Gwalior", "waNumber": "919930790449", "email": "madhav.answers@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d260a93aa0d29874385ad411ca86e49467b99373065f2be9a608beb1d9419145/IMG_20220227_105032.jpg", "scribbleSpace": "- Enjoy cycling, cooking or any kind of social service\\n- Have pledged all my organs (would love to create more awareness on organ donation)\\n- Qualified Chartered Accountant, Graduation from Mumbai", "contribution": "- Basics about organ donation\\n- Experience wrt getting and giving appreciation", "intro": "Businessman, Learner"}',
  '{"name": "Vishakha", "place": "Dombivali, Mumbai suburb", "waNumber": "919650581144", "email": "misakipande@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ef08b96e57e704b0c329fadd1c3100acc0a150097160f735f3b27ec90a76c26d/1599327888567.jpg", "scribbleSpace": "I love classical music, botany, plants and nature trails. I am an avid reader with profound interest in various arts and cultures. Seeking to improve my organizational and time management skills.", "contribution": "My linguistic skills working as a multilingual professional", "intro": "Nature Lover"}',
  '{"name": "Shubhangi Jagdev", "place": "Baner Pashan Link Road, Pune", "waNumber": "918010763583", "email": "shubhangijagdev@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e5775f7b1278d2d7ffa24eab0c96c0cb5463aac23dea6cf3c934569947ffdf81/WhatsApp_Image_2022_04_30_at_10.39.33_AM.jpeg", "scribbleSpace": "I love dancing, being at the beach, moonlit and starry skies, and nighttime because of its stillness! Not into religious or spiritual practices but definitely consider myself a spiritual being. Hoping to learn to wake up early so I can make it on time for my new job!", "contribution": "My area of interest lies in existential therapy; researching spiritual intelligence, self-compassion, and resilience; and creating safe spaces to share and listen to each other. I can share my knowledge on the same with everyone. Alternatively, we could wake up and just dance for like 15 minutes based on everyone\'s song recommendations!", "intro": "Psychologist, Dancer"}',
  '{"name": "Chandra Tej Pasupuleti", "place": "Nagole, Hyderabad", "waNumber": "919492602026", "email": "chandratej.aim@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c1282c68de2afff461169e178b24a588e76aee85c4d81d1a172ebf78972e0a76/IMG_20190825_104445_099.jpg", "scribbleSpace": "I keep changing my hobbies. My interests vary time to time. I would like to learn from people from diverse backgrounds. Especially looking for a mentor to help me evolve as a better individual...", "contribution": "I possess knowledge on programming, software design and also on stock market. I can share my support to extent that is best possible from my end in anyway that I am capable of.", "intro": "Aspiring Story teller"}',
  '{"name": "Sudipta Ota", "place": "C V Raman Nagar, Bengaluru", "waNumber": "919916302703", "email": "otasudipta@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4a9db0da72b547e43a9f382863576bb1688531afcd4fd7defb3c8bd125d28cf7/IMG_20211124_WA0008.jpg", "scribbleSpace": "go-karting, travel, photography", "contribution": "Photography.", "intro": "Video production specialist"}',
  '{"name": "Bhoomica", "place": "Thane Maharashtra", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4520f50334415c5bce286ca56cecf02cd02ade13a452bf15dd0a3316a5f8d6d9/IMG_20220408_220510.jpg", "scribbleSpace": "I like to walk, workout, write, binge watch thrillers!", "contribution": "Not much to share. Not a very talented person!", "intro": "Peaceful soul"}',
  '{"name": "Poorvi Sharma", "place": "Pune", "waNumber": "919167335120", "email": "poorvi.sharma31@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6c4f8936321c71f51a870e9daedd6d0bb241a410d014611a75d1364fb87b7838/20210620_185318.jpg", "scribbleSpace": "When away from work, I enjoy abstract painting and find immense pleasure in bringing her imaginative senses to the canvas.", "contribution": "Haven\'t thought through, but can teach abstract painting.", "intro": "Product Marketing Person, Painter"}',
  '{"name": "Amaira Adwani", "place": "Goa", "waNumber": "919762494832", "email": "amairaadwani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea141221ff2bd8d733fe77e907fca8319c1612b540657ef638d2b24e1929b7f3/IMG_20220424_131518_486.jpg", "scribbleSpace": "I wanna become an early bird \\ud83d\\udc26", "contribution": "French lecturer and 8th to 10th all subjects coaching", "intro": "Lecturer"}',
  '{"name": "Ketaki Deshmukh", "place": "Pimple Nilakh", "waNumber": "919923022686", "email": "ketaki7@gmail.com", "photoLine": "https://api.typeform.com/responses/files/efd9f20f358ce02d0505d29cbf4dacea7bd43fce6806b1ef78453fe3aa8800ad/6ED3BD4F_1158_4056_9DF3_55D2F42B3ECE.jpeg", "scribbleSpace": "IT professional so mostly stressful routine. Day ends late due to meetings so Sleep schedule is impacted. My hobbies are Painting, reading, gardening and playing recreational games with kids, friends and family. I wake up between 5.30 to 5.45 am daily, even if I got to bed late. I expect some flexibility during this program practice just in case there are late meetings and I end the day after 10.30pm due to workload and can\\u2019t wake up at 5am", "contribution": "Meditation + Healing practices and experiences", "intro": "Indian Folk Artist"}',
  '{"name": "Shivani Joshi", "place": "Uttarakhand", "waNumber": "916283124328", "email": "joshishiani701@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f7fdf2fd1a843b1e15d9a0eb5701c1b2909ce23f69d25b041a3ad77372ebed1c/6B694DA2_4284_4BE8_A46C_1075643CDF8C.jpeg", "scribbleSpace": "Interests-Modelling \\nHobbies-Singing \\nplans- travel more \\nExpectations-Early wake up", "contribution": "Engineer Mindset", "intro": "Engineer"}',
  '{"name": "Shaishavi", "place": "Pune", "waNumber": "919769800550", "email": "shaishavikadekar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/70e1d3782d68b9b66cbb21bf86b88c8544f4d2b35fbe27c3376273b019c4b39e/IMG_20220318_103941.jpg", "scribbleSpace": "Plants , Ecology , sustainability , animals and physics :p", "contribution": "Everything about pets and adoption (cats, dogs)", "intro": "Reluctant IT employee"}',
  '{"name": "SriHarsha", "place": "Kismatpur, Hyderabad", "waNumber": "918978955825", "email": "gandrakotasriharsha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f8b56dc932dc1550335310417578abf07e87ad3f7738e63e04a389bf2a02cb66/Screenshot_20210927_072355.png", "scribbleSpace": "I enjoy reading self help books and singing, as well as spending time with my family and going on long trips. I\'d like to lose myself in nature and greenery. One of my terrible habits is procrastination. I\'m working on it right now.", "contribution": "This question made me realise that I am unfit for anything. No issue; I am jack of all, so I can concentrate on one talent and share it with others in the coming days.", "intro": "Fitness freak"}',
  '{"name": "Neil Rohra", "place": "Vastrapur,Ahmedabad", "waNumber": "919825441488", "email": "drneilrohra@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8bd47bd6191920728349dd7a8dc1a9043f669622eb4b72e1dd70007c06bfb9e4/381EB2C7_FB8C_483E_856A_3E3F746C2D16.jpeg", "scribbleSpace": "Full Time Orthopedic Surgeon with serious interest in share market and trading", "contribution": "Surgical knowledge and Knowledge of Share Market", "intro": "Orthopedic surgeon"}',
  '{"name": "Harsha", "place": "Bangalore", "waNumber": "918929800717", "email": "singh.harsha15@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7ea0031e1d514535300f793122a4a00ba09d70ee18a3b3a07a9af41a6929dcd2/C189AAEC_A643_4EDF_AAC2_73465D6118EA.jpeg", "scribbleSpace": "Travelling exploring places, listening music and in to spirituality", "contribution": "Depends, what i can be of any help to anyone", "intro": "HR"}',

  '{"name": "Priyanka Pathak", "place": "Bavdhan", "waNumber": "919890060021", "email": "priyankapathak1401@gmail.com", "photoLine": "https://api.typeform.com/responses/files/744c4e541ff59a7c89e8f2ac019ef6eaa870e3e8d6bd09c18d77119bffadf767/Snapchat_8761780.jpg", "scribbleSpace": "I like to Read, travel and explore new places and I am foodie", "contribution": "Live one day at a time", "intro": "Queen Priyanka"}',
  '{"name": "Amrish Arjun", "place": "Goregaon West, Bombay", "waNumber": "919820342288", "email": "amrish@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0fba598456635861269c10d17cf9529054f099aa6ab61b48032e76249fed6bea/1139600B_03BA_4A2E_9F07_B84F67414ACB.jpeg", "scribbleSpace": "My interests are seasonal", "contribution": "Ideation. Problem solving. Being resourceful. Digital marketing", "intro": "Learner. Doer."}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "DHANESH OSWAL", "place": "PUNE", "waNumber": "919561502193", "email": "dhaneshoswal@gmail.com", "photoLine": "https://api.typeform.com/responses/files/28548d0ff2cb5b00f4b9b145378fdd1316adf611a0da4ad193962d041c0081c2/20211219_232243.jpg", "scribbleSpace": "CRICKET,FOOTBALL,CHESS", "contribution": "I can try motivate other member", "intro": "Love to play cricket and chess"}',
  '{"name": "Janvi Bora", "place": "Yerwada, Pune", "waNumber": "919420920567", "email": "janvibora2004@gmail.com", "photoLine": "https://api.typeform.com/responses/files/46e2bb4481c052f93c39cf2e0f69459a9d3b5dcfc7b26aff70ac63449b24c5cc/20200121_163236.jpg", "scribbleSpace": "I\'m in grade 12, preparing for NEET. I have completed my Masters in Bharatnatyam and also learnt classical music. I love exploring new fields and helping others out.", "contribution": "Music and art", "intro": "Student"}',
  '{"name": "Sunmeet Taluja Marwaha", "place": "Gurgaon", "waNumber": "919717380111", "email": "sunmeett@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2d93424a31a8418e2dec0c677df22506dfe65a529848785f574bf7ea9d0c9d24/ABO_0566.jpg", "scribbleSpace": "I love cooking, making new formulations in skincare and haircare, love to take workshops, love travelling", "contribution": "My knowledge and my optimism", "intro": "Entrepreneur"}',
  '{"name": "Jeenay Patel", "place": "Ahmedabad Gujarat", "waNumber": "918401114221", "email": "jeenaypatel2003@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9c5484a9254f636545e9c086e9bdf721224b6f2f9c63a128fd9239a22fd5f549/04_Jeenay_Patel.jpg", "scribbleSpace": "I love to explore new things and experience it.", "contribution": "No talent right now", "intro": "Explorer"}',
  '{"name": "Neha Jhamwar", "place": "Sahakarnagar", "waNumber": "919604787541", "email": "ar.nehabangad@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a80e04e553db39ff81ecf2495a401c99b18138fa113dfab7ec767724a52eeb31/IMG_20210222_122628669.jpg", "scribbleSpace": "My vision is to have my own art and design studio", "contribution": "Can\'t think of any", "intro": "Artist Architect"}',
  '{"name": "Adki", "place": "Manajari Pune", "waNumber": "919823467393", "email": "amitadki2883@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fc1e91e8f48d679bc048414f10ce0dae2d69e764e7abc93e9d45abe757e7da1f/IMG_20210829_WA0025.jpg", "scribbleSpace": "Cycling is my hobby , expectation are want to be energetic through out the day and develop concentration", "contribution": "management skills", "intro": "Software Engineer"}',
  '{"name": "Janhavi Saraf", "place": "Kothrud pune", "waNumber": "917744978866", "email": "janhavisaraf.23@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c635fc8e2415a179568690c3ad0cf3f88f10ae0efb5f2d9b26e9b4c454496ff8/SAVE_20210926_134718.jpg", "scribbleSpace": "I want to travel the whole world. Love listening to songs and watching movies.", "contribution": "Quilling,paper sketching", "intro": "Student"}',
  '{"name": "Manasi Gadgil", "place": "Andheri Mumbai", "waNumber": "919284932714", "email": "gadgilmanasi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/eb0d1ee4d6bb24f14d62e04e063f3e37ea856e7cf8f42e3ad796a48ed31d7fc9/inbound2724876180844889031.jpg", "scribbleSpace": "I like to sing sketch and drives", "contribution": "Na", "intro": "Banker"}',
  '{"name": "Hema Iyer", "place": "Thane", "waNumber": "919049148884", "email": "hemaiyerb@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5f621d686f7e9e16bb97080f9d9b92e97fbfcf33739a9d1ced0d61f1e8f9bb16/facebook_1615349494345_6775266845531428237.jpg", "scribbleSpace": "hey 5am\'ers! Excited to be here and really dont know what to expect, so I am open to the activities that this group does.\\nI like doing the usual stuff that most people do - movies, spending time with friends and family(sometimes), Netflix (courtesy Lockdown). \\nI am interested in traveling, working out, going for walks, learning languages (I can speak 4 languages right now & want to increase that count), writing (sometimes)\\nworkwise I am a German language specialist and work in a publishing company - currently online", "contribution": "I\'ll take it as it comes, but can help for German language related stuff", "intro": "Optimistic and Positive"}',
  '{"name": "Thiyagesh Prabu S", "place": "Chennai", "waNumber": "918056274740", "email": "thiyageshprabu@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8b0161a2dfe0ea31ee307087aa0f55cebd629898da7540fd793a6f4ac41b853e/IMG_20210224_203256.jpg", "scribbleSpace": "Chartered accountant. Playing violin, Volunteering with Ngo to teach underprivileged kids", "contribution": "Play violin & offer Personal finance ideas", "intro": "Motivator and Tamil poet"}',
  '{"name": "Dipti Patil", "place": "Wakad pune", "waNumber": "918830397209", "email": "diptipatil3485@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3e696e15ed51eb02e329c971099c4e56362a3931402bdeed3050bccef9aa9b3f/IMG_20210412_172615.jpg", "scribbleSpace": "Cooking", "contribution": "No", "intro": "Doctorate in chemistry"}',
  '{"name": "Amogh Chougule", "place": "Sinhagad Road, Pune", "waNumber": "919890696063", "email": "amoghsc@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9b795eb39bda12832aff97e0eac81e6fd71b6aa92e26c502e1291366cec7bb15/9F0D384C_6BEC_4ED3_BC24_70FD8D1E579F.jpeg", "scribbleSpace": "Jack of many, master of some. Here for growth mindset community & accountability", "contribution": "Can share some insights about personal growth. Areas of interests psychology, human nature, relationships, conflict resolution, creative thinking, embracing discomfort, etc...", "intro": "Curious creative"}',
  '{"name": "Leena Patil", "place": "Mulund Mumbai", "waNumber": "919820383123", "email": "leenapatil@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/601c573570d7a96d5ef3fa854711a7e962bb1b66550958462db9c326eb7a3496/IMG_20210506_WA0045.jpg", "scribbleSpace": "I am a happy  go lucky person. Loves to spead happiness everywhere!", "contribution": "Happy to help always", "intro": "Lawyer!"}',
  '{"name": "Debanjana Sinha", "place": "Bangalore", "waNumber": "919911777199", "email": "debu.piyu@gmail.com", "photoLine": "https://api.typeform.com/responses/files/21b0c903f4bca10a4d35493d84a6cb64fc6900ab0a691f455c3fdc61fd24a4e1/IMG_20190913_184013.jpg", "scribbleSpace": "Stationery, fountain pens, Stoicism, Travelling, Japan", "contribution": "Stoicism, philosophy, digital marketing, fountain pens", "intro": "Memento Mori"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Purvi Savla", "place": "Salisbury Park", "waNumber": "918369285163", "email": "purvisavla2016@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f5dabcfd7971fe0f4e8533e42303d8a027d160656bfef514dde937dd09a86162/IMG_20210626_170049.jpg", "scribbleSpace": "I Struggle to sleep, struggle to wake up. I want to change the tag - I am not a morning person", "contribution": "I can show how to make calming zentangles. No prior drawing skills required.", "intro": "Optimistic Wolf, Enthu Entrepreneur, Single Sassy Mummy"}',
  '{"name": "Gaurangi Patil", "place": "Mundhwa", "waNumber": "917447335879", "email": "gaurangilad2013@gmail.com", "photoLine": "https://api.typeform.com/responses/files/70a4f729b340556cdcc6aeb71eebb22cbbdfce081142752fe3e78ce6c2e44266/IMG_20190817_WA0034.jpg", "scribbleSpace": "Love to meditate....very lazy", "contribution": "Nothing", "intro": "Full time homemaker"}',
  '{"name": "Vidya patil", "place": "Katraj pune", "waNumber": "917350014341", "email": "vidyaspatil7@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f38f9be8bd937a584551b44910d36a4fa9627e71b97ca0ab54aed576a33075fc/IMG_20171120_201411.jpg", "scribbleSpace": "Listening music, doing yoga, travelling,want learn sining", "contribution": "Some yoga and health related messages", "intro": "home Baker"}',
  '{"name": "Prashant Dighe", "place": "Warje, Pune", "waNumber": "919763707605", "email": "prashantdighe1495@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0951a2ddfec967dd37c0d168e9ab263148355baa37fa87329202992b7e2d3a34/IMG_20180909_201003_043.jpg", "scribbleSpace": "Listening music, travelling", "contribution": "Experience", "intro": "Sports"}',
  '{"name": "Prateema", "place": "Pratema bidar", "waNumber": "917019425099", "email": "prateemarajole@gmail.com", "photoLine": "https://api.typeform.com/responses/files/346915eb40b415acfe48f2c20ba50b33c4a40f5d5bac3a909b579c9a81afb9e2/IMG_20181110_WA0003.jpg", "scribbleSpace": "Hobby listening music", "contribution": "Experience", "intro": "Melophile"}',
  '{"name": "Vishal Patil", "place": "Dhankawadi", "waNumber": "919923002980", "email": "vpatil717@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0f9945be0723cf7e786f7e0bfc64f4083d95b033271e9c265a33bbe43b0047d6/IMG_20210629_113322.jpg", "scribbleSpace": "Cricket", "contribution": "Life is beautiful", "intro": "Cricket commentator"}',
  '{"name": "Shrikant Thorat", "place": "Bibwewadi Pune", "waNumber": "919890393742", "email": "shreekant@cathorat.com", "photoLine": "https://api.typeform.com/responses/files/9d0fb328aede1f850c81421f8de55306e713945ca1059bd7c78847380494642f/A84547B3_A65F_4060_9F5B_E120C089E188.jpeg", "scribbleSpace": "I want to always remain fit and want to get successful by inculcating good habits , I believe that every has good knowledge, only those succeed who have the power to keep knowledge updated and have best techniques to use it . Always be apart from crowd don\\u2019t be a part of crowd.", "contribution": "Good inspiring thoughts", "intro": "Learner to do better in life"}',
  '{"name": "Shital Waray", "place": "Kharghar- Navi Mumbai", "waNumber": "919773190283", "email": "shital.waray@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a6ffff7ef932178d77d27bab43b5163081b0b4e514d7ca1fef71c84154910c41/IMG_20200703_182006.jpg", "scribbleSpace": "BE, MBA but chose to be Homemaker, like to paint n travel, proud mother of a son.", "contribution": "Not sure as of now", "intro": "Happy Homemaker"}',
  '{"name": "Rutuja Dhamale", "place": "Pune soon to be Bangalore", "waNumber": "919591574593", "email": "rutuja.dhamale@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7a13c1fa178a67ee544a1491ee961bc3c77946b47195d3caa51264899e918a7f/B1673126_C72C_4E2A_BC57_705B40CCFCDB.jpeg", "scribbleSpace": "I am a striving early riser working in the space of nature education.", "contribution": "Session on human-wildlife conflict", "intro": "Nature educator, naturalist"}',
  '{"name": "Dhananjay Mujumale", "place": "B. T. Kawade Road, Pune", "waNumber": "919765988424", "email": "dhananjay.mujumale@gmail.com", "photoLine": "https://api.typeform.com/responses/files/44ad383a6d2cffd948797ef761fd1c5ee8f22960f25635bf34f7d6b0d5427202/Dhan.JPG", "scribbleSpace": "Software Engineer, Farmer and Yoga Practitioner", "contribution": "My knowledge & experiences about Yoga", "intro": "Yoga Practitioner"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Siddharth Biniwale", "place": "Ganesh Mala, Sinhagad road Pune", "waNumber": "919405505902", "email": "sakhasiddha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dbe063adf4940a04a78b4bd017942ef43a6bb0e79fc6c26ba4650772e9bbc491/IMG_20210630_071039.jpg", "scribbleSpace": "I am a Naturalist. Exploring wilderness and understanding ecosystems is a way of life for me. I Birdwatching, wildlife photography interests me a lot. I enjoy listening to the birds and recording bird calls. Currently working on a research project on Avian Bioacuostics (Sounds of birds) in different forest habitats.", "contribution": "a) Tips for wildlife/Bird photography\\n\\nb) DIY tricks to make Bird nests from trash", "intro": "Storyteller, Naturalist"}',
  '{"name": "Brijesh Kumar Bhaiya", "place": "Choolai, Chennai", "waNumber": "919841827966", "email": "bbhaiya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/13dd186e98f4f344c03ea0b7db27493784472cbb657b7f6d87851d9172d749c6/WhatsApp_Image_2021_05_09_at_22.01.48__1_.jpeg", "scribbleSpace": "Travel, Food and exploring new places / avenues are few which i enjoy.", "contribution": "Planning and implementation of an activity.", "intro": "Businessman"}',
  '{"name": "Sanika Surana", "place": "Lasur, Aurangabad", "waNumber": "919404377250", "email": "sanika9surana@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3d1ae8c6b449bb4adb5df7f7b94c5849040c83c1434f51d7992ece28390bc67d/IMG_20210628_011211.jpg", "scribbleSpace": "I am a psychology major student. I love to study. I love planning my day with the help of planners and colourful pens. I am inclined towards every hobby that is creative and healing in nature (doodling, painting, note making, skin care, fitness). I have been working a lot towards maintaining a good balance of things in life. I workout regularly, I eat healthy, I use natural products and now I look forward to better my sleep cycle. I also make a lot of skin care and hair care remedies at home. I am very bubbly and enthusiastic.", "contribution": "Positive psychology tips, doodling, skin care and hair care recipes, experiences, conversations on maintaining a healthy relationship with our loved ones, workouts, conversations about studying effectively.", "intro": "Psychology Student"}',
  '{"name": "prashant saraswat", "place": "raipur chhatisgarh", "waNumber": "917415315833", "email": "saraswat.nitrr@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6b735ceb72e0715819673e2ec6fab58cf555e188c87e66f36320a057fd607c40/IMG_20210721_WA0003.jpg", "scribbleSpace": "interested in reading", "contribution": "I just want to wake up early", "intro": "peace lover"}',
  '{"name": "Gauri Limaye", "place": "Navi Peth Pune", "waNumber": "919371829077", "email": "gauri.gole@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fedaf5f29483c04ed325bd7a9292317499531af60ba81bb28ef79efbd70a1568/IMG_20210110_211420_146.jpg", "scribbleSpace": "I\'m interested in classical music and literature,also interested in language teaching. I love cooking and I am also very talkative person, love lots of people around me", "contribution": "About Marathi grammar, Indian classical music", "intro": "Classical vocalist,teacher"}',
  '{"name": "Vinaya Sambargi", "place": "Mumbai", "waNumber": "919869033624", "email": "vinaya.sambargi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8d51d68f97620ef53299291362bbeb44a7b301fdd284bddc4917554e95456ba4/IMG_20170521_WA0013.jpg", "scribbleSpace": "I like to write, understand human emotions, watch Netflix, Foodie, Bubbling with Creative ideas. Like to live in my space while influencing other\'s", "contribution": "Motivate, Help Wake up, Help to present talent, Help presenting content creatively", "intro": "Exploring life"}',
  '{"name": "Snehal Bari", "place": "Handewadi, Pune", "waNumber": "919284761691", "email": "barisnehal29@gmail.com", "photoLine": "https://api.typeform.com/responses/files/277d683334e71b002528583642b1fa984d6e3811df5c6e5b3eac073a258dfffa/IMG_20210625_175513_087.jpg", "scribbleSpace": "I\'m a yoga enthusiast. Passionate about learning and teaching .  Getting up early at 5 has changed my lifestyle more towards healthy being and it connects me to the nature. Looking forward to stay with this habit lifelong to achieve more than I thought \\ud83d\\ude00.", "contribution": "I can share details of Yogic lifestyle easy to adapt\\ud83d\\udcab\\ud83e\\udd18", "intro": "Yoga Trainer"}',
  '{"name": "Vidya kedari", "place": "Katraj pune", "waNumber": "919850975989", "email": "vidya.ukedari@gmail.com", "photoLine": "https://api.typeform.com/responses/files/803a2ca75ea85ca70aa5f5e45eacf1e79976c8ccce562cdee8129aefc738b15b/20210619_151910.jpg", "scribbleSpace": "Yoga", "contribution": "Yes", "intro": "Yoga practitioner"}',
  '{"name": "Preetee Pushpa-Prakash", "place": "Aundh, Pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d9cce749d2e6bef4fe3ad72e7ad78b2d3d0c8a47fd70b7d1d97adc8d7d3a5df7/IMG_20190603_WA0240.jpg", "scribbleSpace": "Reading, writing, learning, wet waste composting, connecting people, following passion", "contribution": "How to put plastic covers on books so that they last longer", "intro": "Co-traveler"}',
  '{"name": "Vikrant Patil", "place": "Kudawale, Dapoli", "waNumber": "918552969377", "email": "vikrant.patil@gmail.com", "photoLine": "https://api.typeform.com/responses/files/be4ea364bedc0b44c3d0200bc3da32197a5ee1b002bcb18a06bf746368fadd93/vikrant.jpeg", "scribbleSpace": "I can say that mostly I live life. During free time I teach programming, sometimes develop small scale software,  read, play music , help people live happy family life (counselling). As part of living life I spend a lot of time talking to my wife and my daughter, cook food, roam around the river, grow food in the small land that we have, maintain my mud house and trees that I have planted and wonder about the secrets of nature around me.", "contribution": "Health, food, yoga, Relationship Counselling", "intro": "A friend"}',
  '{"name": "Vinodh", "place": "Bangalore", "waNumber": "919986025927", "email": "vinodh.hana@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e9bddbd9a213cc61bd6042d2ea0fda949fd9d2beeabd57b3ed5cbccb5bcfbae5/IMG_20210622_112343.jpg", "scribbleSpace": "Digital Wellness Entrepreneur, sports ,book, music, Travel", "contribution": "Sports, wellness,books", "intro": "IT - Sports - Wellness"}',
  '{"name": "Shrravane Vuyyuru", "place": "Chennai", "waNumber": "919884983213", "email": "shrravane21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ab2e8e825bf478608eb71b72409b71667270836ed5594bf4eb113f16f54a851b/C925DFB5_B1C3_45C0_AAF6_EA4C50BB6EEA.jpeg", "scribbleSpace": "Just another happy go lucky girl, finding new ways to become the best version of myself. Also, someone with an appetite for art.", "contribution": "Just looking forward to learn from you guys for now!", "intro": "CA aspirant!"}',
  '{"name": "Neha Sharma", "place": "Kota, Rajasthan", "waNumber": "918000317261", "email": "neha17shrm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1d3ca1d6516b12934f7bb01afd34d528310ec8df3c78a61df1e115ac0575a1a4/Screenshot_20210630_215149_2.png", "scribbleSpace": "Dancer, painter, roller-skater, hula-hooper, and practicing kindness consciously.", "contribution": "Impromptu short paint /draw session.", "intro": "Abstract painter"}',
  '{"name": "Apurva Patil", "place": "Bhandarkar road", "waNumber": "919822552000", "email": "apurva.patil@spsoftindia.com", "photoLine": "https://api.typeform.com/responses/files/a716e209c56cdd28362a01642de4b56738e4c8d539f1243f128ea6c77afd502e/42EABA11_E919_4F77_9965_D330BCC95626.jpeg", "scribbleSpace": "Nothing to share as such. I am not able figure out", "contribution": "Good in public relation", "intro": "Home maker"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Satyajit Shinde", "place": "Powai, Mumbai", "waNumber": "919869321812", "email": "shinde.satya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/570b5ef08fb1c97e6def281fc96be9bed6490eee96f7dd3c7066f103bc75e60f/20210214_164634.jpg", "scribbleSpace": "IntereseBirding, Wildlife Photography", "contribution": "Na", "intro": "Bird Photographer"}',
  '{"name": "Anurag Subhedar", "place": "Rajarampuri Kolhapur", "waNumber": "917841099654", "email": "anuragsubhedar31462@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a33102d08b2e386e78d8cae73ee15ba8338d17fb5db3618425dbf3feb23d3634/16460514964044956313483442369184.jpg", "scribbleSpace": "I am a student who loves gaming and am trying to be a CA in future", "contribution": "Being confident", "intro": "CA Aspirant"}',
  '{"name": "Gourav Pattanaik", "place": "Bangalore", "waNumber": "919980983583", "email": "pattanaik.gourav@gmail.com", "photoLine": "https://api.typeform.com/responses/files/207eacb102449e34fca29fb8425d12714558589fc8429e0c7ee85302cff7f5ec/image.jpg", "scribbleSpace": "Want to be more disciplined and focused person", "contribution": "My IT journey", "intro": "Software Professional"}',
  '{"name": "Kaustubh Kulkarni", "place": "Dombivli-Thane", "waNumber": "919920787798", "email": "kaustubhmailbox@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bd80bd7f4b011ff8d6595ae084bf7a6ae88f42bfbb7f44af3474161dd6dcd366/Screenshot_2022_02_28_21_35_52_87.jpg", "scribbleSpace": "Love to read biographies and historical contents. Love to go on long drives and explore new places.", "contribution": "Share knowledge content and intersting facts", "intro": "I am a knowledge seeker"}',
  '{"name": "Shrutika", "place": "Baner, Pune", "waNumber": "919970514512", "email": "shrutikacadmane@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ca8ec3c945df7ca81f31d485aef11677bb2ec9b5070df62b80fc190b1bfe6966/inbound9118987176709980952.jpg", "scribbleSpace": "By profression I am an IT engineer by interest I am an artist.", "contribution": "Anybody can paint, just try once and then u will be in love with colours", "intro": "Artist"}',
  '{"name": "Harsh Ramteke", "place": "Nagpur", "waNumber": "919823671410", "email": "harshramtekeindia@gamil.com", "photoLine": "https://api.typeform.com/responses/files/6f5e62038d361c438aa238c22ab79aa0d069b382986e6d0777ebe66cf212c94c/IMG_20200629_112551.jpg", "scribbleSpace": "Interests- Developing, Designing, sharing life lessons and storytelling.\\n\\nHobbies- Painting, reading stories.\\n\\nPlans- As I love designing and developing i am planing to launch my own design firm.\\n\\nI belive each one of us has something to offer to this world. Your success is not decided by your accomplishments but by how many lives you have touched.", "contribution": "I would be happy to share my life experience, stories that inspires me, and my paintings.", "intro": "Artist"}',
  '{"name": "Gargi Srivastava", "place": "Jais, Amethi", "waNumber": "919473900277", "email": "gargisri68@gmail.com", "photoLine": "https://api.typeform.com/responses/files/33329adeab9220cdf4e11d5e1bf9488a4cc326929b89c05fcec9c72dcf08c6a1/20220210_141740.jpg", "scribbleSpace": "Hobby: Reading", "contribution": "Computer Science", "intro": "Assistant Professor Computer Science and Engineering"}',
  '{"name": "NAMRATA KHOSLA", "place": "Kharghar, Navi Mumbai", "waNumber": "919930699651", "email": "naamyk@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ad6c4271d1865f2e4752ef73e2262526c639713a5bbf999759c7d6d7697793e5/8FD79FBD_383A_49A7_B16A_4FB56AE29B8E.jpeg", "scribbleSpace": "To elevate awareness of my spiritual self and create a life of love and abundance", "contribution": "I am a good listener, mentor, counselor, fitness enthusiast", "intro": "Education Counselor"}',
  '{"name": "Snehal Nalgirkar", "place": "Pimple Saudagar, Pune", "waNumber": "919922507197", "email": "snehalpank@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3d0deb314a4d51ada82a6b246feaa7e77ab8bacd2edadf8084e2c28ce9887ffd/Compressed.jpg", "scribbleSpace": "I am a health professional practicing since the last 15 years, practicing yoga and helping people for health issues is my passion. Through this platform I want to discipline my life.", "contribution": "I can share my health tips and with members", "intro": "Homoeopathic and Yoga practitioner"}',
  '{"name": "Rashmi chand", "place": "Powai Mumbai", "waNumber": "918879248582", "email": "rashmichand17@gmail.com", "photoLine": "https://api.typeform.com/responses/files/e155c46a6000667b760c83639dca9bc56ac3ecde67fc9c4137d7328e0ee11095/IMG_20210623_WA0027__01__01__01.jpg", "scribbleSpace": "Writing, reading, crafting and yoga", "contribution": "Not sure", "intro": "Content writer and blogger"}',
  '{"name": "Dr Seema Aiwale", "place": "Pune", "waNumber": "919665460160", "email": "seemasaiwale345@gmail.com", "photoLine": "https://api.typeform.com/responses/files/56629981ae64746e65a7835b3a89c200a2c1a3ca6c1f5662261b2f9292d03997/Screenshot_20210722_230155.jpg", "scribbleSpace": "M health n fitness lover , help people in right way through right nutrition diet spiritual,mental, financial n physical well being . Most interested in Guiding women through women empowerment..", "contribution": "Health ,Nutrition,", "intro": "Gyanecologist SKin and Nutriti"}',
  '{"name": "Prakrthi A N", "place": "Mangalore", "waNumber": "916361696276", "email": "prakrthian@gmail.com", "photoLine": "https://api.typeform.com/responses/files/dc53499818c58c0e54332dc8c0dcbd6f595c5568f49e3665f6568e728a1f5bd9/Prakrthi_Alankaru_Narayana.jpg", "scribbleSpace": "Yoga, cooking", "contribution": "About nanoresearch, nutrition-diet", "intro": "Research student"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Shwetha Kamath", "place": "Thane", "waNumber": "919637864979", "email": "baliga81@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ce416586f2f40a85ec8c235384ed9592546fddec83f67bbe09096c7aaf1a8e0e/Screenshot_20210829_211702.jpg", "scribbleSpace": "I am an optimistic person with a positive vigor. I cherish my work, my family and my friends.", "contribution": "I love to make people smile and I am not an early riser. This entire experience would be a challenge for me.", "intro": "Teaching Professional"}',
  '{"name": "Nikita Auti", "place": "Kothrud Pune", "waNumber": "919730044825", "email": "nikitaauti0410@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72588357f62e09c57aa6e2abf500ab9df9e0b4526454d8a90f4c8e620795ec17/Snapchat_81252179.jpg", "scribbleSpace": "I love listening lectures related to life \\nI always wanna go out and visiting new places", "contribution": "Im teakwondow player", "intro": "Student"}',
  '{"name": "Kalpita Vishwarup bose", "place": "Versova, mumbai", "waNumber": "919004688668", "email": "kalpitavbose@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b92b9a5e229c76692579f437fc660378b2303507a4ef2a7122a07fa4ba295703/6C75D3B3_DB31_4A1F_8094_F381609597AF.jpeg", "scribbleSpace": "I love fitness in all forms mostly weights , yoga , dancing, calisthenics. Love music and singing but currently since six months can\\u2019t due to acid reflux ans stress . Love art creativity in any form. Started my own company 6 yrs back. It\\u2019s been good but faces a difficult time due to lockdown. Also recovering from an Alcoholism led marriage ans hence separation and a complicated relationship .\\nLove nature, believe in natural wisdom and like meeting people. Been working for 24 yrs and still love working. I have a lots of friends and social connections but I have a new fear of being alone. I also mourn the loss of a child.", "contribution": "I can share strategic knowledge about building brands, content creation ,taking care of animals/pets , gardening, yoga, dance too to a certain extent.", "intro": "Graphic designer"}',
  '{"name": "Sunil Wakle", "place": "Wakad pune", "waNumber": "919922446310", "email": "sunilwakle@gmail.com", "photoLine": "https://api.typeform.com/responses/files/34fdd106a5da8bacc664a622710e0944c6c51adcdd03be6aa39c6de4f2abdf60/IMG_20210815_104501.jpg", "scribbleSpace": "Sports, new learning, meditation", "contribution": "Sports", "intro": "Want to be marathon runner"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Dr. Archana Jeevan Shinde", "place": "Nanded city, Pune", "waNumber": "919923797018", "email": "drarchanajshinde@gmail.com", "photoLine": "https://api.typeform.com/responses/files/85e988cb5bdc20bda92be5b944ce6bdf2de953e43d0aea5ba06ee024e428ccff/IMG_20210523_110556.jpg", "scribbleSpace": "Skin and nutrition are my interest areas am exploring. My mission is to help millions of people lead healthy life.", "contribution": "Wellness coach for many lifestyle disorders like diabetes, PCOD, thyroid etc.", "intro": "Entrepreneur"}',
  '{"name": "Aparna Deshpande", "place": "Baner, Pune", "waNumber": "919665011444", "email": "aparnasanglikar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe0a267de8b41fe7063adab94830d27eccebd9561825c16ad93d06031be5670a/454F1613_6844_464C_86AC_913C2916FB52.jpeg", "scribbleSpace": "I am a voracious reader and love listening to classical Indian music. I hope this club helps get some \\u2018me time\\u2019!", "contribution": "I enjoy working with my hands and often find myself working with my 5 yr old on various crafting projects. I can share ideas about the same along with book recommendations.", "intro": "Lawyer"}',
  '{"name": "Preetee", "place": "Aundh, Pune", "waNumber": "919422517129", "email": "opreetee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f0089291d000657537ce06b45abe4469980c244e5feab05e46c6af4149206653/WhatsApp_Image_2021_06_26_at_23.20.25.jpeg", "scribbleSpace": "I am a book. I let the reader read me. Contents of the book: Meditation, Yog, Reading, Writing, Biodiversity, Gardening, Education, Parenting, Mindfulness, Water conservation, Soil conservation, Environment Education", "contribution": "Eco friendly parenting, Screen free parenting, Eating habits, Terrace Gardening, Lazy composting", "intro": "Nature observer"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Anuja Deokar", "place": "ABC Pune", "waNumber": "919604304525", "email": "anudeokar24@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ec6f91b9a1926ded4d0271b5db1635581aaf05df4a30370414f73e4dac05d25f/IMG_0202__1_.JPG", "scribbleSpace": "interests", "contribution": "Finance related", "intro": "Always Confused"}',
  '{"name": "Sonali Tambat", "place": "Panvel", "waNumber": "919975329474", "email": "sonali.tambat21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/759811ca61efa49afa96626f563b8caf5f90d66df06e5a2b972c64c4a84babb0/IMG_20210815_204628.jpg", "scribbleSpace": "Happy to go person", "contribution": "Not specific", "intro": "Professional Social Worker"}',
  '{"name": "Prachi Bogam", "place": "Harigram village, new panvel", "waNumber": "918655573501", "email": "prachi.bogam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f9f3572922c1d4660a1677d2cf2af6d957942cd3634303fa1199a2576f99e47c/IMG_20210728_122321.jpg", "scribbleSpace": "I am home maker now and since not doing any outside job whenever get time I got into the habbit of watching mobile so wanted to get rid of that and persue new habits of reading, walking etc.", "contribution": "I work in the field of early childhood education and language development.", "intro": "Eucationist"}',
  '{"name": "Shaila Patil", "place": "Satara", "waNumber": "919764663030", "email": "shailapatil306@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1a2f92494026dcf1a20243f3ca607bd823bad5668d098a07946b2a6d9464b0ff/IMG_20210726_WA0015.jpg", "scribbleSpace": "Interested in gardening.Love to spend time with nature Want to lead a healthy life.", "contribution": "NA", "intro": "Teacher"}',
  '{"name": "Sunil Makare", "place": "Panvel", "waNumber": "919833571502", "email": "sunil.makare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/458838a83388aecb016711fbb52e3435ec617844781c4e5e144db929048794b6/IMG_20210821_WA0000.jpg", "scribbleSpace": "Taking up this new challenge of early rising to make it no more a challenge.", "contribution": "Can share about own life experiences whenever fit in.", "intro": "Development Professional"}',
  '{"name": "Varsha Reddy Durgempudi", "place": "Saidabad, Hyderabad", "waNumber": "919963402110", "email": "varshadurgempudi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/019ce4543ffee5445f41dd5be0ff6320a1b59f56f256b6f8d43cf42a75bd739a/2019_01_18_09_47_48_791.jpg", "scribbleSpace": "Going to start my PhD in few months and thus want to reset my circadian rhythm by waking up at 5AM", "contribution": "Stubbornness maybe", "intro": "Caffeine addict"}',
  '{"name": "Nikita Garg", "place": "Gurgaon", "waNumber": "919810433924", "email": "garg.nikki1990@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0ac0b192da6a90cde8b22e448e1f22f64d26c48bb307dfb7743f598329762b9b/IMG_4160.jpg", "scribbleSpace": "I am CA by profession, working with Deloitte. I love to do baking and read books in my free time.", "contribution": "I have knowledge in Company\'s audits, making chocolates", "intro": "Chartered Accountant"}',
  '{"name": "Shravan K", "place": "Bangalore", "waNumber": "918792857289", "email": "ksshravan667@gmail.com", "photoLine": "https://api.typeform.com/responses/files/57f53ad8353df912f1901e58765778a9b4f2128e088136c62e422ce68ad52a55/ShravanPhoto_.jpeg", "scribbleSpace": "Yoga, coding, morning walk", "contribution": "Coding", "intro": "Yoga practitioner"}',
  '{"name": "Mahesh Todkar", "place": "Kothrud Pune", "waNumber": "919970272657", "email": "maheshtodkar86@gmail.com", "photoLine": "https://api.typeform.com/responses/files/514f4413691db6bb1007301e785d3c7f5565d5b080fbb32cd319eaf205356a41/Mahesh_Todkar_ID_Photo.png", "scribbleSpace": "I wanted to make habit of early morning wakeup.... As an IT professional currently facing lot of issues with Health and work life balance....", "contribution": "Software Development", "intro": "IT Professional"}',
  '{"name": "Chenchu", "place": "Thiruvallur Tamilnadu", "waNumber": "919080075938", "email": "chenchu1999@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5a9d83ac2a5cae80001a380ca60bcaec88e065cd7f1f85b1899f87eb679e7880/2017018019_1.jpg", "scribbleSpace": "Interested in reading books", "contribution": "I am good at singing", "intro": "I am a student"}',
  '{"name": "Vijay Chougale", "place": "Kolhapur", "waNumber": "919604086304", "email": "vijaychougale987@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d88784855bea8e878f421485cd0b9860bdb3843a9fc1b552691bcdffe930c047/IMG20210418070936.jpg", "scribbleSpace": "By profession I am an engineer. Likewise I am very curious about knowing engineering of life. I want to go deeper into spirituality.", "contribution": "Meditate every day", "intro": "Spiritual"}',
  '{"name": "Rupesh M", "place": "HSR Layout Bengaluru", "waNumber": "918123811015", "email": "rupesh.mokshagundam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/04e764f69c704081c7a4fb67c6387a3b911d199ae2c8e5b85708819ab5c7a8b3/DSC_1663__1_.JPG", "scribbleSpace": "technology,Data,Chess", "contribution": "my work experiences", "intro": "Software Engineer"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Sharvari Patil", "place": "Baner Pune", "waNumber": "919766039388", "email": "sharvari.p@gmail.com", "photoLine": "https://api.typeform.com/responses/files/58eab976724715c3981c2f1b0805181e100b50bbae4f06ce143f1dd8a86226f8/img_4_1626544121665.jpg", "scribbleSpace": "I love exploring new places, meeting new people & spreading positivity.", "contribution": "My travelling experiences, my songs on keyboard & my short poems / shayris.", "intro": "Wanderlust & Photographer"}',
  '{"name": "deepali chaubal", "place": "Bangkok", "waNumber": "66814589440", "email": "samdeep45@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a3a343e571255e103b4b5160fad28a78ef98a5ce67c8d7b59b2617b03e4a4e4b/WhatsApp_Image_2021_07_30_at_3.06.07_PM.jpeg", "scribbleSpace": "I am a people\'s person. I love travelling & storytelling", "contribution": "Story telling", "intro": "A Story Teller"}',
  '{"name": "Pranav Patil", "place": "Parvati Pune", "waNumber": "919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fc949309ee0e158a2e044e0a403d861dcd7e48f6f6869dc5f0ee51124395bd9f/Screenshot_20210624_162326__01.jpg", "scribbleSpace": "Psychologist body-builder, INFJ, Counsellor-Mentor-Advisor \\ud83d\\ude07, Scientist, Astrology-curious, Work-out-holic \\ud83d\\udcaa, Nasha-positive person", "contribution": "1. How to be happy 2. Micro- workouts at home 3. Make good tea 4. Socializing tips 5. Tinker on piano", "intro": "Armwrestling enthusiast"}',
  '{"name": "Seema Kalantri", "place": "Bopal Ahmedabad", "waNumber": "919586499988", "email": "srkalantri@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/fadd501212aa1930e2e04bc7b35404df19751a1d6ab937ef24a8398715195934/IMG_20201212_082556.jpg", "scribbleSpace": "Learner", "contribution": "Good listening Ears", "intro": "Software Professional"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Anshul goel", "place": "New Delhi", "waNumber": "918178738858", "email": "adorable.anshul@gmail.com", "photoLine": "https://api.typeform.com/responses/files/70b79a871836020ba7ae48e251db8ed2f13557284109b582342d7c78e507e299/IMG_20210928_183622.jpg", "scribbleSpace": "Gardening Baking Teaching", "contribution": "Gardening", "intro": "Technical product owner"}',
  '{"name": "Balaji Mankari", "place": "Chandra Layout, Banglore", "waNumber": "917275656561", "email": "balajimankari2015@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a2d7707cca80b159c2d0b7a706e51c964d67559f3f0a70a7202ad6a81a7cdcf4/A8332870_CAA5_4261_842C_CF5EDF046D77.jpeg", "scribbleSpace": "Road trips, trekking, Farming, Teaching, Writing journals, Bike and Car enthusiast, Interested in International politics and relations.", "contribution": "Mediation, and about international politics", "intro": "Voyager"}',
  '{"name": "Rajni Vishwakarma", "place": "Gurgaon", "waNumber": "919718870902", "email": "rajnivishwa1982@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5120233d6a1aebeaf93f992bffcfdafa52f2f6d2cdc8e0908f479a8375df1222/IMG_20191108_184204.jpg", "scribbleSpace": "Single mom", "contribution": "Beauty and wellness", "intro": "Beauty expert"}',
  '{"name": "Ramya Duraisingh", "place": "Bangalore", "waNumber": "917738851113", "email": "ramyaduraisingh@gmail.com", "photoLine": "https://api.typeform.com/responses/files/7c2cc79ec8132070790a1042ca1c7939ef41fe93e8b5dbb815b2101c87b847db/Screenshot_2017_03_06_19_00_42_867_com.miui.home.png", "scribbleSpace": "Trying to form new habits", "contribution": "Nothing as of now", "intro": "Working professional"}',
  '{"name": "Nancy joshi", "place": "Jhansi", "waNumber": "919404727376", "email": "nanjoshi1@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9ad9ca9a0bffd504bb3414466697ec11752df41d73085dcdf6e73eb8c64cc8ed/IMG_20211013_213736.jpg", "scribbleSpace": "I enjoy music", "contribution": "Cooking", "intro": "Teacher"}',
  '{"name": "Priyanka Singh", "place": "Delhi", "waNumber": "918130826975", "email": "priyankasingh.dpd@gmail.com", "photoLine": "https://api.typeform.com/responses/files/69de8a402ab6b76e19df932fb98a4f7320f1583348496470270a9d1bdd54b2cf/IMG_20200724_WA0028.jpg", "scribbleSpace": "Plan to visit whole India before crossing 40", "contribution": "Have not much to share..", "intro": "Government servant"}',
  '{"name": "Manisha Bhujbal", "place": "Balajinagar pune", "waNumber": "918208503607", "email": "manishaabhujbal882@gmail.com", "photoLine": "https://api.typeform.com/responses/files/14a2a1a878e4d7f6356eb3187b0cb43f40c8f58882b3541a7c3c6ac5f171fc34/IMG_20210615_122942.jpg", "scribbleSpace": "I like music and reading and help to people", "contribution": "Yes", "intro": "I am teachar"}',
  '{"name": "Prashant Pednekar", "place": "Nigdi Pune", "waNumber": "918600140600", "email": "prashantped@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6df1ab4af9d4801dd806aacb1de9990e1dec442e2df823c9915c75bd034c3577/FB_IMG_1601687035215.jpg", "scribbleSpace": "Learner and project manager", "contribution": "Project management training ,", "intro": "Constant learner"}',
  '{"name": "Saurabh Agrawal", "place": "Raipur", "waNumber": "918602100608", "email": "srbhagrawal14@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f17ef5b8bb5576ea42db3a857e302fe35ddefae1ca399ed64f4e82ed5795e61d/PicsArt_06_14_11.51.07.jpg", "scribbleSpace": "Progressing to achieve something. Banker by Profession. Like listening music, solving problems.", "contribution": "I am novice to share anything.", "intro": "Banker, reader"}',
  '{"name": "Kartik Kinge", "place": "Wardha", "waNumber": "918975383155", "email": "kingekartik@gmail.com", "photoLine": "https://api.typeform.com/responses/files/634ecaae1fae40f15714c5051fc3aadd932b81b9bedb3f59ad2c37d73c259dc6/IMG_6475.JPG", "scribbleSpace": "Engineer by profession, Traveler at heart. I am always to for coffee to discuss ideas and life.", "contribution": "I am AR VR developer. I can help people understand about these emerging technologies", "intro": "Software Developer"}',
  '{"name": "Aalok Bhuyar", "place": "Nagpur", "waNumber": "919022918347", "email": "aalokbhuyar211@gmail.com", "photoLine": "https://api.typeform.com/responses/files/919f7a88d47a0cad154c89325ae371c44ce37da2da9ade3f03e4503b61d3053b/DP.jpeg", "scribbleSpace": "Student at IIIT Allahabad\\nexplorer", "contribution": "life experiences\\nmy perspective towards things", "intro": "Tech enthusiast"}',
  '{"name": "Mehak Karda", "place": "Sigra Varanasi", "waNumber": "919819427080", "email": "mahakkarda9@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c041863a51fffd0ac6b8f93fddfcc185538659ad52459e1d5a9388a2fff50db6/IMG20210425200224_1.png", "scribbleSpace": "Hi there! How are you ? If \\nI talk about my interests and hobbies, then I love to travel, meet new people, do yoga and meditation, read self help books. I am a nature lover, animal lover and would love to work for social causes. I aspire to become a fashion designer and work in the field of creativity and imagination. I expect that the 5am club is going to bring out the best in all of us. Currently, I am into a phase of analysing and deciding for myself about what are the next steps...", "contribution": "I think I can share few meditation techniques and share my experiences", "intro": "Optimistic bird"}',
  '{"name": "Padmanabhan", "place": "Bangalore", "waNumber": "919900799744", "email": "sapnapaddy@gmail.com", "photoLine": "https://api.typeform.com/responses/files/67a19e8e5f89b82f5e544def0ce40c0a58e89164d382d6a0d81fcb2d21c6532d/IMG_20211127_212336.jpg", "scribbleSpace": "I love to think, i love some me time , i love nature ! I want to access my best potential and stop giving excuses to wake up early!", "contribution": "I could try comedy ,or a bit of singing or i could lead guided meditations or facilitate a quick warm up yoga session.", "intro": "Yoga practitioner"}',
  '{"name": "Manisha J. Hinhoriya", "place": "Aurangabad Maharashtra", "waNumber": "917038958300", "email": "jivisayoga@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f8d8d875b6b54a81fc9315b6e98d67946007881955597c760df9ddd7481c6633/IMG_20210810_101301.jpg", "scribbleSpace": "To teach yoga", "contribution": "I\'m QCI level 3 yoga teacher n registered yoga alliance teacher", "intro": "Yoga Practitioner"}',
  '{"name": "Snehal Gandhi", "place": "Wagholi Pune", "waNumber": "918698093357", "email": "snehal.gandhi10dec@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2ef6d546ef7781b840e3bc05850d8a411b406ed0caf485bdf7a20dbcbb906cee/4D873851_925B_4141_AA99_370CF69CCCEF.jpeg", "scribbleSpace": "Dancing listening to soft music", "contribution": "I m an introvert person I don\\u2019t like to talk to unknown people that\\u2019s why I m participating in 5am club to come out off my comfortzone", "intro": "Photographer"}',
  '{"name": "Vaibhav", "place": "Kolhapur", "waNumber": "919579579680", "email": "vaibhav.h21@gmail.com", "photoLine": "https://api.typeform.com/responses/files/37cbc71c3991e9695caf080ddf79eef81ba1d40a2bf9b8484d0486f6feb17ba2/IMG_20191012_152033.jpg", "scribbleSpace": "Traveling, wild life lover...listening music...dancing...sports..", "contribution": "Jocking, making people laugh", "intro": "Nothing from above"}',
  '{"name": "Pragya", "place": "Pune", "waNumber": "918368154614", "email": "pragya891997@gmail.com", "photoLine": "https://api.typeform.com/responses/files/52527ee6225a3723f16b11ee9ce740ce043232421aa03aa4526e145d2801373d/IMG_20211128_WA0007.jpg", "scribbleSpace": "I want to become an independent & happy person....\\nLove to dance occasionaly....\\nJoining this club to become an early riser & to bring some poaitive change in my life & personality", "contribution": "Nothing specific as of now", "intro": "Medical student.."}',
  '{"name": "Prajakta Advant", "place": "Bavdhan Pune", "waNumber": "919850891130", "email": "prajjoshi19@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8a66fd69e3375b2390e6d19e60d1c8cfc43ea92f630d8094767ebfcaf5a0bc45/DECED0DE_02FE_47DE_AA2C_265A77557D08.jpeg", "scribbleSpace": "Love to listen music, travel", "contribution": "NA", "intro": "Travel enthusiast"}',
  '{"name": "Siva Ranjan", "place": "Bangalore", "waNumber": "919986034737", "email": "sivaranjan.maharana@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b6519849ffac06428a52a085af2a01a8765b4255c6e62c2d3653a9458eafcff6/inbound8731312006887089191.jpg", "scribbleSpace": "I am an introvert. My idea of recreation is painting, biking, gaming, cooking. I am an UX Professional.", "contribution": "Paintin, Photography, Cooking", "intro": "Paint and Photography"}',
  '{"name": "Hema", "place": "Mumbai", "waNumber": "919004261133", "email": "hema.sagare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/248d7e058a125dd56d1d55f9cf223ca956e998861c278714ec463bf5e3392721/PXL_20210513_135425411.jpg", "scribbleSpace": "Hilarious hema as I call myself! Loves to watch birds anywhere they appear:) loves to connect people from different fields..", "contribution": "I can talk about what birds did to me.", "intro": "Bird enthusiast!"}',
  '{"name": "Debasree Dey", "place": "Baner, Pune", "waNumber": "wa.me/+918380078981", "email": "divinedanica@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ffe32615087816e2c9e28cde633bd10c81346b39976e743599cf1e094a2b9a80/778DD2D9_23EC_4A18_B78A_0977820C01F6.jpeg", "scribbleSpace": "An artist who creates beautiful things everyday.\\nQuit the rat race (worked in IT for more than a decade).\\nLove reading books.\\nAddicted to personal growth.\\nAdrenaline junkie, backpacker (25 countries), trekking... love nature..\\nKnow me: debslilarts.com/about", "contribution": "None at 5 AM", "intro": "Creative Entrepreneur"}',
  '{"name": "Gauri", "place": "Bangalore", "waNumber": "917776964261", "email": "gaurivshete@gmail.com", "photoLine": "https://api.typeform.com/responses/files/38201bf3dc88005aa89e242f2b05d50a4c38030adbeeab150084f599b7079fee/inbound2884876785433452472.jpg", "scribbleSpace": ".", "contribution": ".", "intro": "Perpetual learner"}',
  '{"name": "Sachin Sharma", "place": "Kharadi Pune", "waNumber": "919860604507", "email": "bestofsachin@gmail.com", "photoLine": "https://api.typeform.com/responses/files/21aa552e4ecf2be70bcac857da0de38290ae4508db0e113e9997c88562b8cb6a/72F8F4CB_28D2_4FEE_8AB9_80EA77A47E96.jpeg", "scribbleSpace": "An artist, trying to set his routine to learn and achieve more. Currently waking up late, then office from morning till eve, so trying to set some timings to live more!", "contribution": "I\\u2019m a writer, poet, singer and handpan player.", "intro": "Writer, Singer"}',
  '{"name": "Shivani Chavan", "place": "Versova Mumbai and Pratapganj peth Satara. I trav l often", "waNumber": "918591110815", "email": "shivani.chavan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/665a3072a7336cb513bd0ed96f90d6fd41dc3449237398c7eac31e18618972d4/IMG_20211119_WA0000.jpg", "scribbleSpace": "I love almost everything that life has to offer- I read, I run, I cook sometimes, I like eating out, I like watching movies, I like treks, I love morning walks, I like almost everything except watching news!", "contribution": "I have plenty of stories to share. I have attended international camp when I was in school, I left a high paying job to start a business, there are many more. I have won awards for elocution, so I can share tips on it. The list is long. Professionally, I am an expert in lean operations. I headed business excellence for Nerolac.", "intro": "Entrepreneur"}',
  '{"name": "Ajay Kumar Dadi", "place": "Warangal", "waNumber": "918897755822", "email": "ajaykumar.dadi2103@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c4bb585c2f8a2ae2dce8a6d8191dffcafded5972df421a8b48dd11dea457fd4a/IMG_20191124_180757.jpg", "scribbleSpace": "I love thriller and horror movies. Looking for excitement in every aspect of my life.", "contribution": "How to make our lives easy", "intro": "Natural lover"}',
  '{"name": "Nandini Gulhane", "place": "Shashtri Nagar , Nagpur", "waNumber": "918080477075", "email": "nandini.gulhane10@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d4878dd11e9f41fe44bcf8aeeb84828e8c1d53c67aa43f69201d04ceb27a5d6d/20210922_231456.jpg", "scribbleSpace": "Hobbies: love to dance\\nIssues: stressed about career and not able to focus, concentrate on work.", "contribution": "Experiences of this boot camp", "intro": "Enthusiastic Nands"}',
  '{"name": "Ragini", "place": "Noida", "waNumber": "919999673653", "email": "ragini0407@gmail.com", "photoLine": "https://api.typeform.com/responses/files/33b08f3f79218081ef09eff580a8d5b3fa6a3c0ed3327ea7f1ecfb0764f0e661/8C3A2E52_22D6_4E33_A0C7_CBD73C36986E.jpeg", "scribbleSpace": "Love to listen music , writing articles and travelling", "contribution": "Travelling experiences , creative style for events", "intro": "Creative thinker"}',
  '{"name": "Sony Pardeshi", "place": "DSK Vishwa Pune", "waNumber": "919823727376", "email": "sony.rp@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2dda89870504b404828535b1fc1d0d85af1b4d18bdabbe0731da6800a428282c/inbound8776878960993373812.jpg", "scribbleSpace": "Movies, Indian classical instrumental, hiking etc", "contribution": "None", "intro": "Chess, Books, Graphology"}',
  '{"name": "Varsha Bhat", "place": "Kothrud Pune", "waNumber": "918007811906", "email": "varshabhat2693@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d41e18f5d88ba90e13fac879237a03c386b1040f5761e0edf9953efee3749d89/Screenshot_20211130_170959_2.jpg", "scribbleSpace": "Would like to wake up to workout and meditate every day", "contribution": "Na", "intro": "Analytics Analyst"}',
  '{"name": "Rashmi S. Tupe", "place": "Narhe, Pune", "waNumber": "919922263074", "email": "rashmitupe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/5f3e61bbde89b3d74910d7fb104413ff7bce9021ea5d27c9bb7e62d0bfe7370e/20210204_210048.jpg", "scribbleSpace": "Travelling, reading etc.", "contribution": "Simple and wonderful life", "intro": "Academician"}',
  '{"name": "Jyoti Dubole", "place": "Bhayandar (w)", "waNumber": "917977372009", "email": "jyotishindedubole12345@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0d7b91eb0ec5621bf02ad8b1f461c7c0d08824ab05f3c0214ef9b09d392ea6ce/Screenshot_2021_04_02_15_35_00_35.png", "scribbleSpace": "I am a teacher and I have done MA. Bed .  In marathi .", "contribution": "Can\'t say for sure", "intro": "I am a teacher"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Mrudula Vaidya", "place": "Sahakarnagar No1, Pune", "waNumber": "919860554912", "email": "mrudulak1981@gmail.com", "photoLine": "https://api.typeform.com/responses/files/665e607bb0d5930ce3e4afb880e70e44732cba9a2ed863d243b2e750f15a52d1/Mrudula.jpeg", "scribbleSpace": "My hobby : Reading", "contribution": "Nothing Special...", "intro": "Software Developer"}',
  '{"name": "Pallavi milin khairnar", "place": "Vadodara Gujarat", "waNumber": "919408081443", "email": "pallunam@gmail.com", "photoLine": "https://api.typeform.com/responses/files/a6652af5799c6c65f1431ab451be7c5fdee8e1067a1ba611b2d6d92b631759e6/20211118_221402.jpg", "scribbleSpace": "Music,reading", "contribution": "Recipes,teaching experience", "intro": "Self employed"}',
  '{"name": "Dr Archna Sahay", "place": "Katraj, pune", "waNumber": "917218250608", "email": "sahayarchna@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f1b91e6205e428c1a4c5b7a49c63688a6fee0b5f55543ac6c0329b882f448c68/IMG_20210822_WA0040.jpg", "scribbleSpace": "Reading,writing, visiting places", "contribution": "I hv teaching and research experience", "intro": "Assistant professor"}',
  '{"name": "Snehal Bhutada", "place": "Hyderabad", "waNumber": "919959668174", "email": "snehalbhutada@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4c80a9e496ac790abba09dadefa2095ab8c6030220f7ba36e270697463c49a32/C972D366_60F3_446F_8612_2BBE1CC07374.jpeg", "scribbleSpace": "I like travelling, yoga, and dance", "contribution": "I can share HR Knowledge with all", "intro": "I am peace loving person"}',
  '{"name": "Devesh Deshpande", "place": "Nagpur, Maharashtra.", "waNumber": "919372329548", "email": "devadeshpande14@gmail.com", "photoLine": "https://api.typeform.com/responses/files/260f04def6022b0a57d2acdb6017ff78e2c33fc0a95cbebc97989fcb576cc094/IMG_20201114_WA0006.jpg", "scribbleSpace": "I am passionate in VLSI. I am thankful for whatever I have got till now. I love to help people and I believe in collaboration.", "contribution": "Just Keep giving without any expectation of return. Focus on the present, do your karma and focus on the controllables of your goal.", "intro": "Peace Lover"}',
  '{"name": "Protima Venugopal", "place": "Undri Pune", "waNumber": "919823699865", "email": "protimafeb@yahoo.co.in", "photoLine": "https://api.typeform.com/responses/files/f7f4539985d571bfa6200d6128171114c92cf17983bb3139a9701a057e16106a/Polish_20211114_135325588.jpg", "scribbleSpace": "A freelance writer and fine artist who wants to grow more as an artist and a musician. Joined the club to be on track on my career goals and my physical and mental well being", "contribution": "Music and arts", "intro": "Writer and artist"}',
  '{"name": "Mrs Meghana Mahajan", "place": "Bandra, Mumbai", "waNumber": "919619369154", "email": "meghananm@gmail.com", "photoLine": "https://api.typeform.com/responses/files/caa4fd03f5f4a06c7944745576f6149c298d3048e323cece95fb5587bac3e31e/16380759566581125839450540654837.jpg", "scribbleSpace": "Solving sudoku, baking", "contribution": "Just want to observe as of now", "intro": "Yoga practitioner"}',
  '{"name": "Harshada N. Kulkarni", "place": "Bibawewadi punpune", "waNumber": "919422317191", "email": "nirav.v.kulkarni@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0421946037c315ab340536a31a2d0c426ee4806e2749d033e22b6fff283797b8/IMG_20210829_223947.jpg", "scribbleSpace": "Drawing is my hobby, love to explore new things ,plans to be healthy n and happy ..love to read books do many things which will explore my inner strength.", "contribution": "Have excellence intreating infertility cases any and everything with homeopathy .love to do councelling.", "intro": "Homoeopathic consultant"}',
  '{"name": "SANIYA PANDKAR", "place": "Dhanori Pune", "waNumber": "919822909021", "email": "manupandkar176@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8273b261f86761fea9f284852e16b96eb4de91f53b894b3a01a0bfa7961e819f/IMG_20210228_173322_528.jpg", "scribbleSpace": "I am a spiritual person....I love meditating reading spiritual books and meeting new people ...looking forward to new experiences", "contribution": "Meditation experiences", "intro": "Yoga Practitioner"}',
  '{"name": "Bipin Advant", "place": "Bavdhan Pune", "waNumber": "919881491130", "email": "bipinadvant@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0812841b8ee5debf0c45f8cb0b51efe97bebefabdda55805fd21163381928960/IMG_20210530_234901.jpg", "scribbleSpace": "Music, positive thinking.\\nLooking for life long habit of waking up early", "contribution": "Power of gratitude. Some good music links", "intro": "IT professional"}',
  '{"name": "Abhay Nandan", "place": "Muzaffarpur", "waNumber": "917667969872", "email": "abhaynandan88@gmail.com", "photoLine": "https://api.typeform.com/responses/files/c57f40d273c3e966632d8b2daeb7be0571952f4d1f04b87b22968b00ec0a259b/16382696806654746519312171195325.jpg", "scribbleSpace": "Business, spirituality,singing . Want to utilise the morning Time for gym,reading and relation building", "contribution": "Singing, entrepreneurial experience of 10years in different sectors like education, agriculture,poultry, FMCG distribution.", "intro": "Singer, entrepreneur"}',
  '{"name": "Avadhoot", "place": "Borivali- Mumbai", "waNumber": "919850887914", "email": "avadhootkhare@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d1415ea8bfbb1455f58dd414bc737786360bb40eba36ee28bd0005d58f8eff2d/1C7E0A77_CDDA_49D2_8FEA_2ADA676ED4CC.jpeg", "scribbleSpace": "I love Travelling. Always be happy", "contribution": "Rotary Machine specialist, additionally I am an Investment adviser", "intro": "Rotary Engineer"}',
  '{"name": "Priyanka Yeruva", "place": "Hyderabad", "waNumber": "917013030364", "email": "priyamyers@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe71b929805493180c3e4c5a94df4f7fb654e41b6c269bd4ef7abe9f9e8c5746/IMG_20211120_110857.jpg", "scribbleSpace": "Cooking", "contribution": "NA", "intro": "Content specialist"}',
  '{"name": "Paras Chhabra", "place": "New Delhi", "waNumber": "919999656662", "email": "paraschhabra96@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b44e03ec40a118aa68550ad82ef06ea7cf8127d29a4deaea3cd50a22e9c5c7aa/20210123_075025.jpg", "scribbleSpace": "Learning yoga and on the path to understand spirituality.", "contribution": "Podcasting for beginners", "intro": "Software Engineer"}',
  '{"name": "Deepa Murthy", "place": "Mira Road East, Mumbai", "waNumber": "919004343718", "email": "advocatedeepamurthy@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bd3ed5e00e4b3b9be805ee3363011757df7a0bce5845268e92afb9a56c5f51eb/IMG_20211028_124627.jpg", "scribbleSpace": "I love reading, dancing, singing, art etc. I want to enjoy each moment of my life \\ud83d\\ude42", "contribution": "Art & craft activities", "intro": "Reader"}',
  '{"name": "Shravani", "place": "Hyderabad", "waNumber": "919440550580", "email": "shravani.thurlapati@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3c55b479cff21533ee2768402691b1438b2f84de03eba7620e6c62b227662c4d/Screen_Shot_2021_11_30_at_9.51.32_PM.png", "scribbleSpace": "Good listener, Nature Lover, Interest in Yoga, Art and Music", "contribution": "Something about Yoga and Healthy eating", "intro": "Yoga Enthusiast"}',
  '{"name": "Smita Mehetre", "place": "Baner, Pune", "waNumber": "919890395931", "email": "rtsmita@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78b6dd4862fe15231a94b90ff0b39fef5c5f5052b14bcaa9c03a81d9e72677fc/IMG_20210511_WA0001.jpg", "scribbleSpace": "Practice Iyengar yoga, interested in full time farming, love slow sustainable lifestyle. Developing interest in reading.", "contribution": "I love to do handwork, sewing, crochet, making something out of waste. Made many small projects using left over fabric", "intro": "Sustainable living practitione"}',
  '{"name": "Bhoomica Asnani", "place": "Thane Mumbai", "waNumber": "919987540067", "email": "bhoomica.asnani@gmail.com", "photoLine": "https://api.typeform.com/responses/files/858bcafe379ed59115e899addff0d80019c66182daa62c730749283bb2fefaf6/IMG_20211106_WA0065.jpg", "scribbleSpace": "Wanna fulfill dreams of my kids", "contribution": "Experience of being a mother.", "intro": "Creative Keedaa"}',
  '{"name": "Pallavi Dhoot", "place": "Pune, Senapati Bapat Road", "waNumber": "919822008007", "email": "pallavidhoot@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/481eb608bce182acc4df23d94aae588e4f8c34ad3e573cb78f2d5b430c9ec35e/16250665649071373088638.jpg", "scribbleSpace": "I am a homemaker mother.\\nA researcher by \\nnature; passionate about nature, environment, health and spirituality. I facilitate a Naturopathy Awareness and Practice Program by the name \'Healthy Me\'", "contribution": "I would love to share about health, nutrition, natural healing, medicine free life & about parenting", "intro": "An Avid Explorer"}',
  '{"name": "Pranav Patil", "place": "Parvati Pune", "waNumber": "919822322184", "email": "pensafe@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fc949309ee0e158a2e044e0a403d861dcd7e48f6f6869dc5f0ee51124395bd9f/Screenshot_20210624_162326__01.jpg", "scribbleSpace": "Psychologist body-builder, INFJ, Counsellor-Mentor-Advisor \\ud83d\\ude07, Scientist, Astrology-curious, Work-out-holic \\ud83d\\udcaa, Nasha-positive person", "contribution": "1. How to be happy 2. Micro- workouts at home 3. Make good tea 4. Socializing tips 5. Tinker on piano", "intro": "Armwrestling enthusiast"}',
  '{"name": "Manisha Bhosale", "place": "Kothrud Pune", "waNumber": "919890039898", "email": "manisha_bhosale@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/1e4bbc7211010e211bda51616531b08e07a837838e0fa54dd183685ab434e3d8/8F9D9EC9_8A9E_4C6F_A639_A8EA95954313.png", "scribbleSpace": "Certified Yoga Trainer. Took up yoga as an activity to improve health and also as a fruitful activity after quitting the job. Presently the routine is a bit disturbed due to minor health issues and also lack of discipline. Trying to get my energy and health back by following Pallavi Dhoot\\u2019s HealthyMe programme and making dietary and lifestyle changes, trying to wake up early being one of them", "contribution": "I could try to answer any queries related to yogasanas, pranayama and shuddhi kriya", "intro": "Yoga practitioner"}',
  '{"name": "Phani Raj Bhargava", "place": "Hyderabad", "waNumber": "919000988633", "email": "phani.moksha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2a91048a8eff67f4bf3e9c7ad14010fd4334c1663dd20ac2005e538c13ab2117/20210829_213919.jpg", "scribbleSpace": "Passionate about startups and teying to build one of them", "contribution": "I can share about what not to do in business .. \\ud83d\\ude04", "intro": "Entrepreneur"}',
  '{"name": "Sagar Gangurde", "place": "Kanjurmarg East, Mumbai.", "waNumber": "919594960105", "email": "sagarspg@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ee5b0ff4cb64d5ba42dce3e53849c6d2c755d7599fb1a55e768db99fae58b4d8/B7326774_BE55_4756_BB62_905ED898B6DC.jpeg", "scribbleSpace": "I work for an international NGO which works in peace building. I am also a comprehensive sexuality educator and facilitator. I want to maximise my time and my potential and hence want to form some powerful habits. Waking up early being one of them and hence i would like your support in forming this habit.", "contribution": "Peace building, conflict transformation, coaching, NLP, NVC, IFS, comprehensive sexuality education, peace education, mediation and negotiation skills.", "intro": "Peace Builder"}',
  '{"name": "Kalpesh Dhruve", "place": "Pimple Saudagar, Pune", "waNumber": "919923145886", "email": "kdhruve2@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ea83a0c26ddf8b60eb285cbadb84e08531c0a59f7fa76597a26ece43fc7106a6/20210102_120936.jpg", "scribbleSpace": "Outdoor Sports, Making short phrases/Rhymes, Working towards redeeming myself", "contribution": "Financial, Investment Advisory,", "intro": "Practising Chartered Accountant"}',
  '{"name": "Tejaswini Kapure", "place": "Kalyaninagar", "waNumber": "919881236959", "email": "tejaswini.kapure@gmail.com", "photoLine": "https://api.typeform.com/responses/files/3d424da8b8743e840e4e6a09497f53486a0ad40506e52bdb2754a3bd6dc1da32/E6DF5570_C231_46B7_9BAE_D8B14A2EFD0F.jpeg", "scribbleSpace": "Just another human, trying my best to go through this journey of life.", "contribution": "I can help you with your Public speaking\\nI can also help with editing, delivering your speech. :)", "intro": "Funny meme lover"}',
  '{"name": "Pramod Mujumdar", "place": "Pune", "waNumber": "919923456651", "email": "pramod.mujumdar51@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8b3cc8de7343a496f72b124ed85100d41bd48fc71e43b4a42fbdef435ea62e58/IMG_20211129_183721_499.jpg", "scribbleSpace": "I am fun loving person, love to make new friends and innovative cooking makes me happy", "contribution": "Cooking", "intro": "Innovative chef, Candid Photog"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Padma", "place": "Chennai", "waNumber": "919444078614", "email": "padmathiagarajan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/fe16a6ce8c705ae59745549a0cadf4e2d7d2872eb339264a1f3fc0e491d6153f/1610697823797.jpg", "scribbleSpace": "Chartered accountant  by profession.. Fitness, art, music is my passion..", "contribution": "Importance of being fit.", "intro": "Chartered accountant by profession.. Fitness, art and music is my passion.."}',
  '{"name": "Rohit Khandagale", "place": "Nanded city, Sinhaghad Road", "waNumber": "919922677787", "email": "khandagalerohit@gmail.com", "photoLine": "https://api.typeform.com/responses/files/0a8ea31bc5b4d20a2a4ccfc39ebaf1a029aba14f91521fce24b9a1ba59e9de7c/16397254336872945257994777555211.jpg", "scribbleSpace": "No time to sharpen the axes in routine. This time will be for personal studies. Plus working out can be added health advantage!", "contribution": "Accounts Finance Taxes Surya namaskar", "intro": "Job - Accounts And Finance"}',
  '{"name": "Srividya Thyagarajan", "place": "Balewadi Pune", "waNumber": "917774033934", "email": "srividya.t@gmail.com", "photoLine": "https://api.typeform.com/responses/files/50372477a5272ed924a20cbcf956f7f4fb0a2f47237c9c9de9e3a1cba0fe1044/Thyagarajan_Srividya.jpg", "scribbleSpace": "Love reading, solving puzzles, reading up on current affairs. like to keep fit doing yoga and meditation", "contribution": "I am an IT consultant", "intro": "Aspiring coach"}',
  '{"name": "Tanu gupta", "place": "Bangalore", "waNumber": "917042603175", "email": "tanu.artsbay@gmail.com", "photoLine": "https://api.typeform.com/responses/files/98c7f9f05792b3c8fc5a7d9d79ee05d572b8360923f1330b0c18a4d1fb564e9c/inbound5087688750979114799.png", "scribbleSpace": "I love to paint.", "contribution": "About art", "intro": "Artist"}',
  '{"name": "Shabda Dongaonkar", "place": "Wagholi, Pune", "waNumber": "919970515365", "email": "shabda13@gmail.com", "photoLine": "https://api.typeform.com/responses/files/72e2f9d641f5f6c8e0dba6700ee2b895d5bcfbaf5be93f155d62d95f3fe52f1a/91D952D5_9FD7_425A_9DEF_A1B63FB0C604.jpeg", "scribbleSpace": "My plans for 2022 is to keep myself fit and happy", "contribution": "Helping others , I am more inclined towards literature side , spiritual side, I like to communicate with people", "intro": "IT professional"}',
  '{"name": "Seema N", "place": "Panaji Goa", "waNumber": "919820268434", "email": "bloggingseema@gmail.com", "photoLine": "https://api.typeform.com/responses/files/656f9045bcc65ecb8a1f742c27918125610900b5185745a10f07fa420e3c6fad/IMG_20210303_162204.jpg", "scribbleSpace": "I believe that every life on earth is as important as the other", "contribution": "Benefits of plant based lifestyle", "intro": "Vegan"}',
  '{"name": "Poonam Jain", "place": "Aurangabad", "waNumber": "917276024515", "email": "poonam1409jain@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1cbaf18f1c04fd9079790a3e3c6f3d1d60e71ed78e3740894af28cecd39ccf41/DC54FC7F_BFA8_49E2_B70A_63D6EEE1F926.jpeg", "scribbleSpace": "Expectations: want to take control over myself, over my thoughts and have mental peace and try to be consistent in whatever i want to achieve. \\n\\nPlan : Currently in a job from last 1.5 years i am happy there but wanted to build something of my own", "contribution": "I did a recent trip to Jammu Kashmir which was a eye opener for me to step out of my comfort zone and do something different i can share all about that also if in case anyone is having any issue with computer science languages i will try to help\\ud83d\\ude05", "intro": "Software Engineer"}',
  '{"name": "Ajinkya Patil", "place": "Connaught Place, Aurangabad", "waNumber": "919421315195", "email": "hsc793@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d7e3299d153d7d2bce3293a11d8f0a5faa434740688fde8fd1794a749ae0a2b9/1526396008597_1531819332186.jpg", "scribbleSpace": "Love cooking. Exploring new cultures through food. Learning through History and tracking International relations & Geopolitics", "contribution": "Nothing specific but will try to give valuable inputs", "intro": "Connoisseur Introvert"}',
  '{"name": "Chetnadeepak", "place": "Chennai", "waNumber": "918870978331", "email": "chetnadeepak24@gmail.com", "photoLine": "https://api.typeform.com/responses/files/15835b93297b3d7dac9e871bc36e35091f89db8d3f50e60eabe42a6dcefc1480/inbound5000761570209175484.jpg", "scribbleSpace": "I want to start a school.", "contribution": "I m talented to give speech.", "intro": "Homemaker"}',
  '{"name": "Jagdevappa Birajdar", "place": "Solapur", "waNumber": "919545819734", "email": "jagdevbirajdar@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9c8f4173995671cf3b9960687d78042f50c38f649ea02d6c173e4c7fd93d8ec1/20210804_182108.jpg", "scribbleSpace": "Reading and yoga", "contribution": "Knowledge about Mathematics", "intro": "Teacher"}',
  '{"name": "Surendra Pimparkar", "place": "Ahmednagar", "waNumber": "917276252855", "email": "sursangam43@gmail.com", "photoLine": "https://api.typeform.com/responses/files/675c007a137fe70f5638677a3de3eccadeac556fe7596a15bfa16383823c34a6/DSC_3427.jpg", "scribbleSpace": "Earlier used to wake up at 4 am for studies & daily routine..... But over period of time lost my habit of early rising...tried many ways but could not get up early..... Thats y joining this course.. So that i can get up early & carry on with my exercise shedule, vocal riyaj of music,do some meditation & make my day wondrful, cherrful", "contribution": "Beimg a medical professional can share experience on health related matter", "intro": "Medical professional"}',
  '{"name": "Tirthesh Prajapati", "place": "Dehgam, Gandhinagar", "waNumber": "919591519500", "email": "tirtheshp@gmail.com", "photoLine": "https://api.typeform.com/responses/files/cf865ea9fd7a81513c9e8112784a480a8c782d5720f37e6b32a9809d7d85d540/IMG_20210310_WA0004.jpeg", "scribbleSpace": "Expect life to keep surprising me", "contribution": "Product management and banking", "intro": "Product Manager and a trekker"}',
  '{"name": "Vishesh Sharma", "place": "Bhilwara", "waNumber": "917073901183", "email": "vishesh3108@gmail.com", "photoLine": "https://api.typeform.com/responses/files/54619b0ca56dadafc32bf10abc8d46df23432bcad0d8faa879d96a6d19a56aea/headshot.png", "scribbleSpace": "Hobbies - Reading books, Guitar, Reading Technical Blogs, Public Speaking. In process of breaking mental barriers.\\nI want to develop habit of waking up early every morning. I have done it few times but for some or other reason I stopped it. I want to show myself it\'s just an imaginary mental barrier that I cannot wake up so early. Hence want to be part of this club. Also, my prior experience has shown that it\'s easy to build a habit in association with like minded people.", "contribution": "I am part of Toastmasters club and a fitness enthusiast. Also, I am very keen of reading books. Currently I am reading self-help genre. \\nI am a software developer by profession.", "intro": "Fitness Enthusiast,Toastmaster"}',
  '{"name": "Kulkarni sonal", "place": "Parvati Pune", "waNumber": "919923390543", "email": "sskulkarni012@gmail.com", "photoLine": "https://api.typeform.com/responses/files/18631e17a3fcc0149fea0183164b4a7c5bceaa3b0d04fb8a11e04b549d8286d7/16381621215562895113400167110771.jpg", "scribbleSpace": "Reading,dancing, live each moment of life, accept the things and forgive who hurt you life is more easier", "contribution": "I am working in IDT, good listner and counselor", "intro": "Classical dancer"}',
  '{"name": "Amar Osho", "place": "Hazaribagh, Jharkhand", "waNumber": "918971577751", "email": "amar.osho@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9a7bf11477addbf72eccca03634c3fcdd285f10dfa0bd211662eec2c6c03c0e9/IMG_20211225_124415_582.jpg", "scribbleSpace": "I am a happy go lucky person who believes in enjoying the journey rather than reaching the destination. In my free time I read, run, trek, travel, play all kind of indoor and outdoor games. I am a firm believer of - \\"Karma karo, fal ki chinta nahi\\"", "contribution": "Not sure about this. Maybe Yoga or my experience of Vipassana", "intro": "Attentive Student"}',
  '{"name": "Rahul Deshpande", "place": "Nigdi Pune", "waNumber": "918308236900", "email": "rahuldeshpande11@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f71fb84ab290f792e99b272cafd95268ef585b42f3889ee2f195eac2b9830fa4/IMG_20211222_155910.jpg", "scribbleSpace": "Not able to spent time on things I like", "contribution": "NA", "intro": "Lifetime learner"}',
  '{"name": "Shankar Krishnamoorthy", "place": "Puttaparthi Anantapur District", "waNumber": "919004699819", "email": "shankar_k123@yahoo.com", "photoLine": "https://api.typeform.com/responses/files/ba4d69dc14611440bf60a912dbd4d77500a3b54b06457478d1252854ffd52d30/IMG_20210311_WA0115.jpg", "scribbleSpace": "Spiritual Books,Music", "contribution": "Whistling", "intro": "Spiritual Aspirant"}',
  '{"name": "Parth Agarwal", "place": "Najibabad, Uttar Pradesh", "waNumber": "917078609133", "email": "parthagarwal1984@gmail.com", "photoLine": "https://api.typeform.com/responses/files/744a0a0e3c2a4eadecd87cf78aeeaece870b01ee3a40439837133987648c8e25/20210619_122820.jpg", "scribbleSpace": "I want to change how education works in India/", "contribution": "I can share things about freelancing, productivity and visualisation", "intro": "educator, entreprenuer"}',
  '{"name": "Hrushikesh Kokate", "place": "Kothrud", "waNumber": "917083051238", "email": "hkokate@gmail.com", "photoLine": "https://api.typeform.com/responses/files/59b35fb40f41b2e11116b32ba61e69c1d8fb7c4be1d4d67e4397b2b3e879c276/2F8E10CE_EC05_4B59_B6AF_23F770445262.jpeg", "scribbleSpace": "Food freak , baker, like to follow healthy lifestyle", "contribution": "Travel experience,", "intro": "Peace Sicker"}',
  '{"name": "Renu Shirokha", "place": "Mumbai", "waNumber": "919004087387", "email": "rshirokha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/6f0139a6cdcff3e995d15abce2ab2cf40095526fd4c2e1819956cce7af0ccb58/603BFBEB_4733_4023_B4C8_C84F32DD982A.jpeg", "scribbleSpace": "I m architect as well as artist. My hobbies are reading, travelling and playing with my dog. My plans are concentrate my growth in self development as much as possible got this year.. looking forward to meet you all soon", "contribution": "Well, i would like share the more we look within ourself .. everything falls on it places on outside", "intro": "Architect & artist"}',
  '{"name": "Anjali Arya", "place": "place", "waNumber": "919326294879", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Swati shilpi", "place": "Mumbai", "waNumber": "918999670813", "email": "swatishilpi63@gmail.com", "photoLine": "https://api.typeform.com/responses/files/04040a56bee3b3066efc9590df32405e758bce70d031ab223f966698f01bb3a2/PicsArt_11_02_02.44.11.jpg", "scribbleSpace": "Trying to get back on track since covid\\ud83d\\ude05", "contribution": "Kind of into painting?!", "intro": "Doctor (intern)"}',
  '{"name": "Dr.Priti More", "place": "Pimle saudagar", "waNumber": "919823230551", "email": "drpritien@gamil.com", "photoLine": "https://api.typeform.com/responses/files/613f64af6ed97a8efd27a5cec1542dd043b60613b14db0afdd76e607feb19b36/IMG_20160229_WA0020.jpg", "scribbleSpace": "I am positive person.", "contribution": ".", "intro": "Fulltime Mother"}',
  '{"name": "Shilpa Gaur", "place": "Makrana Rajasthan", "waNumber": "919794378287", "email": "shilpa020489@gmail.com", "photoLine": "https://api.typeform.com/responses/files/78c34a36457b354f74fc0d396098e0aaa08f3e6d6e7f0c8746ea785584143142/E0D59C16_1EB9_45FC_9C1D_BB89F55CDE93.jpeg", "scribbleSpace": "Reading, Want to get fit and get more done everyday", "contribution": "Functionally I can help in marketing and strategy related things. I work with a gaming studio so anyone looking to understand about this area can connect with me. I can help buddy up with people in micro projects for a week ( like finishing a small online course or book in a week or small sprint like not eating junk etc). I can be a good responsibility partner.", "intro": "Habitual Reader"}',
  '{"name": "Ashish Sharma", "place": "Malad East-Mumbai", "waNumber": "919619730766", "email": "ashish.at.iit@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9f0cb9e10c7165dcc7c7fa4dd5b212236ed3e4ff026eb363a0a2afe4da70fd02/1AB42CCD_8F6C_4D78_BB87_CF9D4EB9FA00.jpeg", "scribbleSpace": "Sports, outdoor activities, looking forward to regulate life", "contribution": "Not sure as of now.", "intro": "Yoga Practitioner"}',
  '{"name": "Anjum", "place": "Bavdhan", "waNumber": "919881069879", "email": "anjumysr@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bdd1b4e1ecda9b013413404a525fe48d7f14250a53174d39fe50024abc770402/356B2130_24EF_4034_9DDB_4DDC37CEF38B.jpeg", "scribbleSpace": "Like writing Shayaris, exploring spirituality.. and want to being myself and live life on my own conditions", "contribution": "Human Resource", "intro": "Shayar"}',
  '{"name": "name", "place": "place", "waNumber": "waNumber", "email": "email ", "photoLine": "photoLink", "scribbleSpace": "scribbleSpace", "contribution": "contibution", "intro": "into"}',
  '{"name": "Neel Gadave", "place": "Rajarampuri, Kolhapur", "waNumber": "917620380986", "email": "neelgadave@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ac7f16923022d361a6587c71ced12c0801d9b699a1a71b3137b6f7a21e74cb4f/WhatsApp_Image_2022_01_30_at_21.27.12.jpeg", "scribbleSpace": "Listening music, sports, video games", "contribution": "I would like to share all knowledge and experience I possess", "intro": "Student"}',
  '{"name": "Swati Shilpi", "place": "Mumbai", "waNumber": "918999670813", "email": "swatishilpi63@gmail.com", "photoLine": "https://api.typeform.com/responses/files/da3d96e6a05be45ae3334bf98c14b2563d6dfdaf859774f6351fa09ae8266404/PicsArt_09_04_11.44.59.jpg", "scribbleSpace": "Yy", "contribution": "Positive", "intro": "Doctor"}',
  '{"name": "Ashwin Muralidharan", "place": "Thalassery Kerala", "waNumber": "919497517513", "email": "ash.muralidharan@gmail.com", "photoLine": "https://api.typeform.com/responses/files/203f4bc2c78ec9aec3a12d4e2b0a4141bf4b40890a31f935807a7760e3e99ac9/20220313_071606.heic", "scribbleSpace": "Playing cricket, reading, to come out of my comfort zone, laziness and procrastination", "contribution": "I sing occasionally, play cricket, have some experience in criminal court proceedings", "intro": "Lawyer"}',
  '{"name": "Sreevidya", "place": "Kerala", "waNumber": "917373040330", "email": "sreevidya.sumeru@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9eeba91133adb8710938b0be549d59fb9dcac256e883492df994a3ef397f269f/IMG_20220109_WA0050.jpg", "scribbleSpace": "Drawing, spirituality and travelling", "contribution": "As an artist i could share my works and fine are knowledge", "intro": "Artist"}',
  '{"name": "Manan Shah", "place": "Salisbury park", "waNumber": "919860951111", "email": "manan@mindventures.net", "photoLine": "https://api.typeform.com/responses/files/8b6d0a5262aad69926d31bc853450308ab4f3618bc74db2540eda32dc918cdd1/IMG_20220216_WA0036.jpeg", "scribbleSpace": "Yoga, Gym, Pet parent, Ambitious, Good Vibes only", "contribution": "Meditation, communication, photography, team leading,", "intro": "Born 2 Win"}',
  '{"name": "CA SHIVBHAVAN YADAV", "place": "Ahmedabad Gujarat", "waNumber": "918264051656", "email": "shivbhavanyadav@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f23a47b098a4acf4cc276e6732e163ade9bbd6f6eb4b3fabd21d0969ec6c247a/IMG_20210705_WA0004.jpg", "scribbleSpace": "My hobbies reading the Self Development Books to feel Joyones, Happiness and Motivated ourself. And Be Better than Yesterday. There is Always room for best. Success Begins in State of the Mind.", "contribution": "Always To be Positive every Situation in the Life. Do the One Thing Every Day which you Sacre you.", "intro": "CHARTERED ACCOUNTANT"}',
  '{"name": "Priya", "place": "Pune kharadi", "waNumber": "918412934370", "email": "priyanjalianilpriya@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bd421860ad35ce16067c801325783593be993dda9e5a840ea42006fabca36c5e/Snapchat_345017126.jpg", "scribbleSpace": "I am artist", "contribution": "I like dance", "intro": "I am good listener"}',
  '{"name": "Maitra", "place": "Deccan, Pune", "waNumber": "919226237709", "email": "maitreyee107@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d051c942a6726257eafae2d05a91d7583d6fb58b040eff52dda156e87f46a5c1/IMG_20220220_WA0005.jpg", "scribbleSpace": "Writing, counselling, coffee", "contribution": "Good films, books, psychology", "intro": "Social worker, counselor"}',
  '{"name": "Neil Gandhi", "place": "Mumbai", "waNumber": "918104080300", "email": "drneilgandhi@gmail.com", "photoLine": "https://api.typeform.com/responses/files/52cd51170c49e5f367b84d9e4b341c805a44d8f94a7a1d1329b740b2087fd000/tF6vy5g_E58.jpg", "scribbleSpace": "I am a Doctor turned Music Producer wanting to change my daily habbit and sleep cycle.", "contribution": "Piano teaching producing music for the fellow members", "intro": "Music Producer"}',
  '{"name": "Kavya Agarwal", "place": "Najibabad uttar pradesh", "waNumber": "918923520231", "email": "kavyaagarwal1984@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8f16d53f1e2a925feb56ba3c48ba7cc56a2b2eca24b6e2f468ab8672b63a15e6/VID_20220630_104707.mp4", "scribbleSpace": "Intrest is on personal development", "contribution": "Badminton", "intro": "Nature lover"}',
  '{"name": "vinayak sharma", "place": "Pune", "waNumber": "916350210163", "email": "vinayak0504@gmail.com", "photoLine": "https://api.typeform.com/responses/files/922b7e0120de28aa25145ab9b6032116535b7840c26bd930c1273e5b5dd1669f/IMG_20210821_224708.jpg", "scribbleSpace": "Resident doc, Musician, love hindi, poetry, cats, may write a song some day; and want to CHANGE so I\'m here. \\ud83d\\ude42", "contribution": "Music, poetry, my knowledge for MBBS, MD PATHOLOGY", "intro": "\\u0915\\u0932\\u092e, \\u0915\\u0935\\u093f\\u0924\\u093e\\u090f\\u0902, guitar, \\u0939\\u093e\\u0930\\u094d\\u092e\\u094b\\u0928\\u093f\\u092f\\u092e, \\u0936\\u092c\\u094d\\u0926, \\u0938\\u0902\\u0917\\u0940\\u0924\\u0964 Resident Doctor, Pathologist in making, maarwadi, introvert, cat person \\u0914\\u0930 \\u0939\\u0930\\u093e\\u092e\\u0916\\u094b\\u0930\\u0964 DPU Pune, Jodhpur."}',
  '{"name": "Triptee Singh", "place": "Thane Maharashtra", "waNumber": "919833577655", "email": "dashytriptee@gmail.com", "photoLine": "https://api.typeform.com/responses/files/06a542e4f94aa5e18004f1cfbe6232c21422773d973a0b9c80ee1d6479e705c2/IMG_20220526_093115.jpg", "scribbleSpace": "On a quest to be more disciplined in life", "contribution": "Knowledge about Retailers", "intro": "Passionate for soulful life experiences"}',
  '{"name": "Sanjana Swaroop", "place": "Koramangla, Bengaluru", "waNumber": "918511134081", "email": "sanjana.swaroop@gmail.com", "photoLine": "https://api.typeform.com/responses/files/736d3b21cb315aaf9a64c90b7275bf6d970336db8008688340e09c921a0385e1/C06CBC2D_B535_449D_85C5_886EBDF386D0.jpeg", "scribbleSpace": "I enjoy reading, gardening, swimming and walking in my spare time. I dabble in wire jewellery making and oil painting to challenge myself creatively. I\\u2019m on a quest to better myself by taking charge of my day, time, & energy. Looking to build a sustainable life that incorporates holistic growth mindset. I do sometimes struggle with getting out of inertia and while it\\u2019s evident that old habits and patterns that no longer serve us must be shed and replaced with better ones, I sometimes am unable to keep at something long enough to make the veritable changes.", "contribution": "I\\u2019m an international corporate lawyer by training and have experience in brand building and business development in various industries including education, environmental, mental health space, etc. I would be happy to lend my support and share my knowledge with anyone that requires it.", "intro": "Social entrepreneur"}',
  '{"name": "Jhnanesh Somayaji", "place": "Bangalore", "waNumber": "917760225103", "email": "jsomayaji89@gmail.com", "photoLine": "https://api.typeform.com/responses/files/4d28e0b5f60f66ffd3feebc8b69398a3de63166f837b68285c7c83fb2757435c/_6168165901912879105_121.jpg", "scribbleSpace": "Love music, travelling, spirituality, inspiring people and expectation is to wakeup at 4am w/o alarm and start meditating.", "contribution": "I can share many aspects on many miarcles that has happened. I would love to discuss about on my insights and understandings on energy and experiences", "intro": "I am a PhD by qualification, musician by heart, Nature lover and spiritual seeker."}',
  '{"name": "Dhanush V", "place": "Vellore Tamilnadu", "waNumber": "916382386411", "email": "dhanushvm619@gmail.com", "photoLine": "https://api.typeform.com/responses/files/b068170802382eeebe4da9009604423d3a487073d52cd756f28e7e42270e35d0/Snapchat_1487925903.jpg", "scribbleSpace": "I am interested in bettering myself", "contribution": "I don\'t have much knowledge because right now I am studying in Chartered Accountant(CA), I am trying to Improve my knowledge day by day, but I experienced many things In my life. The main thing is \\" Procrastination will ourself \\".", "intro": "Student"}',
  '{"name": "Tanvee", "place": "Guwahati, Assam", "waNumber": "918638884267", "email": "tanveedey@gmail.com", "photoLine": "https://api.typeform.com/responses/files/2d209d3f6f727690807a2f25a2617186938548207d66489b7dc5383e629ab575/IMG_20210214_224247.jpg", "scribbleSpace": "Reading books, Exercising are my interests. I want to build the habit of waking up early, be among the early risers.", "contribution": "Maybe a few life experiences.", "intro": "I love to read."}',
  '{"name": "Nitin Vatwani", "place": "Ashok Vihar, Delhi", "waNumber": "919871825454", "email": "contact_vatwani@hotmail.com", "photoLine": "https://api.typeform.com/responses/files/2865efc620ee5602789dc1d0f4c7fd884367e06150739dd8b8447afa26ee84ee/42CCD168_AD97_489A_8792_43E2735CAD83.jpeg", "scribbleSpace": "Work Life Balance", "contribution": "Retail and Wholesale trade Management", "intro": "Business Consultant"}',
  '{"name": "Dhanamma Ganechari", "place": "Shelgi Solapur", "waNumber": "917218164569", "email": "ganecharid@gmail.com", "photoLine": "https://api.typeform.com/responses/files/215fb6c9bc315c3cafa3481bf7fc3f0d5f1eda9f9f97ee62c2aca34a8e9d5f20/Screenshot_2021_0512_232804.png", "scribbleSpace": "Learning new things", "contribution": "Yoga, Accountancy, tally etc", "intro": "Teacher"}',
  '{"name": "Kirti", "place": "Pune", "waNumber": "919975059796", "email": "bhutadakirti@gmail.com", "photoLine": "https://api.typeform.com/responses/files/1df01052b550e1d46e670b574a94c8b9593ed09608411addf6e93cfed4eb921c/IMG_20220330_141004.jpg", "scribbleSpace": "Teaching", "contribution": "Nothing", "intro": "Yoga practitioner"}',
  '{"name": "Sujit Khakale", "place": "Kalyan west", "waNumber": "919820567429", "email": "khakalesujit@gmail.com", "photoLine": "https://api.typeform.com/responses/files/bb7339788ce936502cc5cc72be6b7830cecd037ce8e74cd25e70229ec804cc1d/Screenshot_20191004_094830_Gallery.jpg", "scribbleSpace": "I am a consistent learner.", "contribution": "Petions & Focused on target.", "intro": "Supply chain"}',
  '{"name": "Maitreyee", "place": "Deccan Gymkhana, Pune", "waNumber": "919226237709", "email": "maitreyee107@gmail.com", "photoLine": "https://api.typeform.com/responses/files/8fc0a8efa02bdbc2f49ca9e813651eb9951dc320f40baae0ba9c52dd13b63fc4/IMG_20220320_124233.jpg", "scribbleSpace": "Writing, counselling", "contribution": "Psychology", "intro": "Social worker"}',
  '{"name": "Nilisha F", "place": "South Goa", "waNumber": "919552234801", "email": "f.nilisha@gmail.com", "photoLine": "https://api.typeform.com/responses/files/9f8c5fea59b0dd9d258a5ee4dd1247e0370b7c6e2b9c0b6969169b86c30a3dfd/Me.jpg", "scribbleSpace": "Beach bum, enjoy music, need to focus on my health and wellness thats why joined the club. On a weird spiritual journey. Am going to use the morning time for meditation.", "contribution": "I stay in Goa, so i can share everything about the place. My business revolves around coconuts so anyone as crazy abut coconuts as me can share stuff with them. I am also a bachata and salsa dancer", "intro": "Coconut Entrepreneur"}',
  '{"name": "RRJ", "place": "Howrah", "waNumber": "919836848488", "email": "rachitarathi003@gmail.com", "photoLine": "https://api.typeform.com/responses/files/f14858580053476a1e5f2f3116f921ff6b5d6d915820e2c972d37cf21ec4167d/IMG_20220425_172441.jpg", "scribbleSpace": "I enjoy arts", "contribution": "Accoutancy", "intro": "Aspiring growth"}',
  '{"name": "Priyanka Khade", "place": "Nerul, Navi Mumbai", "waNumber": "919969922023", "email": "priyasvhat@gmail.com", "photoLine": "https://api.typeform.com/responses/files/ad8e62b1faac90af8bbfb05f7e2a5f8180c580f5b6dd319251b028d467cd96ba/IMG_20220319_WA0009.jpg", "scribbleSpace": "I did my graduation in 2018, since then preparing for govt exams but couldn\'t achieve anything yet. Now I am getting married soon but I am jobless and being dependent on others is not what I like. Continuing the preparation but for that self management is very much required and that\'s why I am joining 5 AM club.", "contribution": "I like creative writing, I do write few things of my interest in marathi language. I ride bike as well as car.", "intro": "Graduate"}',
  '{"name": "Dhanya", "place": "Coimbatore Tamil nadu", "waNumber": "918870480283", "email": "dhanyanju@gmail.com", "photoLine": "https://api.typeform.com/responses/files/649673b27719894e2a0d10066ad62a010f455581ce9626d4177a29f0a1234ca7/CCA5C019_F9C0_4E22_9E7E_B741495958C3.jpeg", "scribbleSpace": "My hobbies include playing violin , Surfing net . I am a data engineer by profession", "contribution": "My few personal experiences where I made a mistake and realised it", "intro": "Data engineer"}',
  '{"name": "Yogini Medadkar", "place": "Shivaji Nagar Pune", "waNumber": "919503814046", "email": "yogini04@gmail.com", "photoLine": "https://api.typeform.com/responses/files/cfada40beb00944d2e5ae50ac7c5749a07abb8516610819fdc571aa75be3d2dd/38B0195B_0E08_470B_961B_F708C1F28AEA.jpeg", "scribbleSpace": "Get into discipline and do what is decided .. start life beyond profession and acceptance to self are my motos to join this group. I want to bit loosen up myself and want to develop take it easy approach", "contribution": "I am finance domain expert and can motivate others to fight against life odds", "intro": "Practising Chartered Accountant"}',
  '{"name": "Sarita Purswani", "place": "Kharadi, Pune", "waNumber": "919158886552", "email": "", "photoLine": "https://api.typeform.com/responses/files/2b2a7cceed9d2c4b01282c15aaa2c5f9e103e9b8cd99b9127b5e687529acb1ed/576F6CCC_9160_471D_AFCE_415C68AA17AF.jpeg", "scribbleSpace": "Not sure", "contribution": ".", "intro": "Educationist"}',
  '{"name": "Yamini. D", "place": "Chennai", "waNumber": "919940112513", "email": "yaminiher@gmail.com", "photoLine": "https://api.typeform.com/responses/files/d7abeca884983ab52711d698867adf0496f8aa6bd7f9178c90f68827001e49c2/20190914_155737.jpg", "scribbleSpace": "Fitness freak and nature lover", "contribution": "Positive thoughts", "intro": "Nature and bird lover"}',
 
]


var takenOut = [
  ]